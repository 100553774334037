import { ConfigApi, createApiRef, FetchApi, IdentityApi } from "@backstage/core-plugin-api";
import { Entity } from "@backstage/catalog-model";
import { IGetAllSecretsResponse, ISecretResponse, IAuditRecord } from "../GithubSecretsModel";

export const githubSecretsApiRef = createApiRef<GithubSecretsService>({
  id: "plugin.githubsecrets.service",
});

export class GithubSecretsService {
  private readonly backendUrl: string;
  public readonly frontendUrl: string;
  private readonly fetchApi: FetchApi;
  private identityApi:IdentityApi;
  
  public static GitHubProjectSlugAnnotationKey: string =
    "github.com/project-slug";

  constructor(options: { config: ConfigApi; fetchApi: FetchApi; identityApi:IdentityApi }) {
    this.fetchApi = options.fetchApi;
    this.backendUrl = options.config.getString("backend.baseUrl");
    this.frontendUrl = options.config.getString("app.baseUrl");
    this.identityApi = options.identityApi;
  }

  async isRepoOwner(userName: string, entity: Entity): Promise<any> {
    const url = `${this.backendUrl}/api/proxy/scmservice/GithubSecrets/${userName}/ManageSecretsRole`;

    try {
      const {token} = await this.identityApi.getCredentials();

      const response = await this.fetchApi.fetch(url, {
        method: "POST",
        body: JSON.stringify(entity),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status == 200) {
        return await response.json();
      } else {
        return false;
      }
    } catch (error: any) {
      return false;
    }
  }

  async getAllsecrets(userName: string, entity: Entity): Promise<any> {
    const url = `${this.backendUrl}/api/proxy/scmservice/GithubSecrets/${userName}/GetAll`;

    try {
      const {token} = await this.identityApi.getCredentials();

      const response = await this.fetchApi.fetch(url, {
        method: "POST",
        body: JSON.stringify(entity),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      if (!response.ok) {
        throw Error("could not fetch the repo secrets");
      }
      const data: IGetAllSecretsResponse = await response.json();
      return data.secrets;
    } catch (err: any) {
      console.log(`Error: ${err.message}`);
      throw err;
    }
  }

  async getAuditRecords(userName: string, entity: Entity): Promise<IAuditRecord[]> {
    const url = `${this.backendUrl}/api/proxy/scmservice/GithubSecrets/${userName}/GetAuditRecords`;

    try {
      const {token} = await this.identityApi.getCredentials();
      const response = await this.fetchApi.fetch(url, {
        method: "POST",
        body: JSON.stringify(entity),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      if (!response.ok) {
        throw Error("could not fetch the repo secrets");
      }
      const data:IAuditRecord[] = await response.json();
      return data;
    } catch (err: any) {
      console.log(`Error: ${err.message}`);
      throw err;
    }
  }

  async addUpdateSecret(
    userName: string,
    entity: Entity,
    secretName: string,
    secretValue: string
  ): Promise<any> {
    const url = `${this.backendUrl}/api/proxy/scmservice/GithubSecrets/${userName}/Upsert`;

    try {
      const {token} = await this.identityApi.getCredentials();
      const responseData: ISecretResponse = { message: "" };
      const response = await this.fetchApi.fetch(url, {
        method: "POST",
        body: JSON.stringify({
          secretName,
          secretValue,
          backstageComponent: { ...entity },
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      responseData.statusCode = response.status;

      if (!response.ok) {
        const msg = await response.json();
        throw Error(msg);
      }

      responseData.message = await response.json();
      return responseData;
    } catch (error: any) {
      console.error("An error occurred while adding/updating secrets:", error);
      throw Error(error);
    }
  }

  async deleteSecret(
    userName: string,
    entity: Entity,
    secretName: string
  ): Promise<any> {
    const url = `${this.backendUrl}/api/proxy/scmservice/GithubSecrets/${userName}/${secretName}/Delete`;

    try {
      const {token} = await this.identityApi.getCredentials();
      const response = await this.fetchApi.fetch(url, {
        method: "DELETE",
        body: JSON.stringify(entity),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw Error("could not able to delete the repo secrets");
      }
      const data: any = await response.json();
      return data;
    } catch (error: any) {
      throw Error("could not able to delete the repo secrets");
    }
  }
}
