import { Entity } from "@backstage/catalog-model";
import { ConfigApi, createApiRef, FetchApi, IdentityApi } from "@backstage/core-plugin-api";
import { SelectItem } from "@backstage/core-components";


interface IPullRequestResponse {
    url: string;
    title: string;
}

export class DomainChannels {
    domain: string = "";
    channels: IReleaseChannel[]= [];
    static default: DomainChannels =  new DomainChannels();
}

export interface IReleaseChannel {
    channel: string,
    environments: string[]
}

export interface IAvailableRelease {
    version: string;
    created: Date;
  }
  
export interface ICloudProviderLocation {
    atmos: string,
    accountId: string,
    cloud: string,
    region: string,
    vpcId: string,
}

export interface IDeploymentParams {
    entity: Entity,
    channel: string,
    environment: string,
    version: string,
    opsRepoOrg: string,
    opsRepoName: string,
    previousRelease?:any,
    rollBackToVersion:string,
    cloudProviderLocations: ICloudProviderLocation[]
}

export class DeploymentResponse{
    public message: string = "";
    public success: Boolean = false;
    public pullRequest: IPullRequestResponse | undefined
    static Success(message: string, pullRequest: IPullRequestResponse):DeploymentResponse {
        return {
            message,
            success: true,
            pullRequest
        };
    }

    static Failure(message: string): DeploymentResponse {
        return {
            message,
            success: false,
            pullRequest: undefined
        }
    }
}

export interface IDeploymentService{
    getAvailableReleasesAsync(entity: Entity, channel: string, env: string): Promise<SelectItem[]>;
    createDeployment(params: IDeploymentParams, rollback:boolean): Promise<DeploymentResponse>;
}

export const deploymentsApiRef = createApiRef<DeploymentService>({
    id: 'plugin.deployments.service',
  });

export class DeploymentService implements IDeploymentService {
    
    private readonly backendUrl: string;
    public readonly frontendUrl: string;
    private readonly fetchApi: FetchApi;
    private identityApi:IdentityApi;

    public static GitHubProjectSlugAnnotationKey: string = "github.com/project-slug";

    constructor(options: {config: ConfigApi; fetchApi: FetchApi; identityApi:IdentityApi}) {
        this.fetchApi = options.fetchApi;  
        this.backendUrl = options.config.getString("backend.baseUrl");
        this.frontendUrl = options.config.getString("app.baseUrl");
        this.identityApi = options.identityApi;
    }

 
    async getAvailableReleasesAsync(entity: Entity, channel: string, env: string): Promise<SelectItem[]> {
        
        const {token} = await this.identityApi.getCredentials();
        
        const projectSlug = entity?.metadata?.annotations?.["github.com/project-slug"] || '';
        const [owner, repo] = projectSlug.split('/');
        const url = `${this.backendUrl}/api/proxy/scmservice/CodeRepositories/${owner}/${repo}/ReleaseChannels/${channel}/Deploy/${entity.metadata.name}/${env}`;
        var response = await this.fetchApi.fetch(url, {
            method: 'get',
            headers: new Headers({
                'Authorization': `Bearer ${token}`
            })
        });
        var releases:IAvailableRelease[] = await response.json();
         
        return releases.map(x => 
        {
            return {
                label: x.created.toString(),
                value: x.version
            }
        });
    }

    async getCurrentReleasesAsync(entity: Entity): Promise<IAvailableRelease[]> {

        const {token} = await this.identityApi.getCredentials();

        const projectSlug = entity?.metadata?.annotations?.["github.com/project-slug"] || '';
        const [owner, repo] = projectSlug.split('/');
        const url = `${this.backendUrl}/api/proxy/scmservice/CodeRepositories/${owner}/${repo}/CurrentReleases/${entity.metadata.name}`;
        var response = await this.fetchApi.fetch(url, {
            method: 'get',
            headers: new Headers({
                'Authorization': `Bearer ${token}`
            })
        });
        var releases:[] = await response.json();
         
        return releases;
    }

    async createDeployment(params: IDeploymentParams, rollback:boolean){

        const {token} = await this.identityApi.getCredentials();

        const data = 
        {
            component: params.entity.metadata.name, 
            releaseTag: params.version, 
            environment: params.environment,
            opsRepoOrg: params.opsRepoOrg,
            opsRepoName: params.opsRepoName,
            rollBackToVersion:params.rollBackToVersion,
            cloudProviderLocations: params.cloudProviderLocations
        };
        const slug = params.entity.metadata.annotations 
                    ? params.entity.metadata.annotations[DeploymentService.GitHubProjectSlugAnnotationKey]
                    : "";
        const [owner, repository]: string[] = slug.split("/");

        let url;
        if(rollback)
        {
            url = `${this.backendUrl}/api/proxy/scmservice/CodeRepositories/${owner}/${repository}/ReleaseChannels/${params.channel}/Rollback`;
        }
        else
        {    
            url = `${this.backendUrl}/api/proxy/scmservice/CodeRepositories/${owner}/${repository}/ReleaseChannels/${params.channel}/Deploy`;
        }
       
        try{
            const response = await this.fetchApi.fetch(url, {
                    method: "POST", 
                    body: JSON.stringify(data), 
                    headers: {
                        "Content-Type": "application/json",
                        'Authorization': `Bearer ${token}`
                    }});
            if(response.status===200){
                const data: any = await response.json();
                const prInfo: IPullRequestResponse = {
                    title: data.title,
                    url: data.htmlUrl
                }
                return DeploymentResponse.Success("Successfully created Deployment.", prInfo);
            }
            else{
                const data: any = await response.json();
                return DeploymentResponse.Failure(`Creating deployment failed with response : ${data.status} - (${data.detail})`);
            }
        }
        catch(error: any){
            return DeploymentResponse.Failure(`Creating deployment failed with error '${error.message}'.`);
        }
    }
}