import React from 'react';
import {  Typography} from '@material-ui/core';
import { InfoCard } from '@backstage/core-components';
import Divider from '@material-ui/core/Divider';
import { makeStyles } from '@material-ui/core';
import { Box} from '@material-ui/core';

import CloudDoneOutlinedIcon from '@material-ui/icons/CloudDoneOutlined';
import DeploymentListCard from './DeploymentListCard';

const useStyles = makeStyles(() => ({
    title: {
      fontWeight: 'bold',
      fontSize: '18px',
    },
    subtitle: {
      fontSize: '12px',
      marginTop: '5px',
    },
    icon: {
        fontSize: '3.8rem',
        marginRight: '10px',
      },
    container: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
    },
    textColumn: {
      marginRight: '120px',
    },
    listItem: {
      marginBottom: '15px',
      display: 'flex',
      alignItems: 'center',
    },
    tableContainer: {
      flex: 1, // Take remaining space
    },
 
  }));

const DeploymentComponentCard = ({data}:any) => {

    const classes = useStyles();
   
    return (
      <InfoCard  variant="gridItem">
        <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  padding: '20px',
                  width:'100%',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    width:'100%',
                  }}
                >
                  {/* Content for the first inner box */}
                  <CloudDoneOutlinedIcon className={classes.icon} />
                  
                  <Box>
                    <Typography variant="body1" className={classes.title}>
                      {data?.length}+
                    </Typography>
                    <Typography variant="caption" className={classes.subtitle}>
                      Deployments done in the last 7 days
                    </Typography>
                  </Box>

                </Box>

                <Divider variant='middle'  style={{ width: '100%', marginTop: '10px', marginBottom: '10px', height:'1px' }}/>
            
                <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                  width:'100%'
                }}
                >
                  <div> 
                      <Typography style={{ marginLeft: '10px' }}>Top 5 Deployments</Typography>
                      <DeploymentListCard data={data}></DeploymentListCard>
                  </div>  
                  
                </Box>
              
              </Box>
            </Box>
          </InfoCard>
    )
}

export default DeploymentComponentCard;