import {
  DomainExplorerContent,
  ExploreLayout,
} from '@backstage-community/plugin-explore';
import React from 'react';

export const ExplorePage = () => {
  return (
    <ExploreLayout
      title="Explore the Vonage ecosystem"
      subtitle="Browse our ecosystem"
    >
      <ExploreLayout.Route path="domains" title="Domains">
        <DomainExplorerContent />
      </ExploreLayout.Route>
    </ExploreLayout>
  );
};

export const explorePage = <ExplorePage />;
