import { useApi } from '@backstage/core-plugin-api';
import { catalogApiRef } from '@backstage/plugin-catalog-react';
import { TextField } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { FieldProps, UiSchema } from '@rjsf/utils';
import React, { useCallback, useEffect } from 'react';
import { useSetState } from 'react-use';
import useAsync from 'react-use/lib/useAsync';
export const allowArbitraryValues = (uiSchema: UiSchema): boolean =>
  (uiSchema['ui:options']?.allowArbitraryValues as boolean) ?? true;
export const SystemPicker = ({
  required,
  uiSchema,
  rawErrors,
  formData,
  idSchema,
  onChange
}: FieldProps<string>) => {
  
  const catalogApi = useApi(catalogApiRef);
  const [state, setState] = useSetState<any>();
 
  // on combo change
  const changed = (state: any) => { 
    setState(state);
    if (state.system){
        onChange("resource:"+state.system); 
    }

  }
 
  // loading entitities matching the filter criteria
  const { value: entities, loading } = useAsync(async() =>
    {
      const defaultFilters:any = (uiSchema && uiSchema['ui:options'])?(uiSchema['ui:options']?.defaultFilters):{};
      const customFilters:any = (uiSchema && uiSchema['ui:options'])?(uiSchema['ui:options']?.customFilters):undefined;

      let entities = await catalogApi.getEntities({ filter: { ...defaultFilters } });
      
      //if defined in template, applying custom filter
      if(customFilters && entities?.items?.length > 0 )
      {
        entities = executeCustomFilter(customFilters, entities)
      }
      return entities;
    });

    const executeCustomFilter = (customFilters:any, entities:any) => {

      switch(customFilters.type)
      {

        case 'SpecTypeDefault' : return filterSpecTypeDefault(entities); 
                              
        default: return entities; 
      }
    }


  const filterSpecTypeDefault = (entities:any) =>{

    entities.items = entities.items.filter((item:any) => !item?.spec?.type || item.spec.type == "default")

    return entities;
  }  

  // setting the entityRef/System using substring logic
  let entityRefs = entities?.items.map(e =>  
    {
      return  e.spec?.domain || e.metadata.name.split('-')[0];
    }
  )

  // on system drop down select
  const onSelectSystem = useCallback(
    ( _: any, value: any ) => {
      changed({ system: value || ''});      
    },
    [changed],
  );

  //
  useEffect(() => {
    
    if (entityRefs?.length === 1) {
      changed({ domain: entityRefs[0] || '' });
      }
  }, [entityRefs, changed]);

  
   
  return (
    <>
      <FormControl
        margin="normal"
        required={required}
        error={rawErrors && rawErrors.length > 0 && !formData}
      >
        <Autocomplete
          
          disabled={entityRefs?.length === 1}
          id={idSchema?.$id}
          value={(state.system as string) || ''}
          loading={loading}
          onChange={onSelectSystem}
          options={(entityRefs || []).filter((value, index, self) => self.indexOf(value) === index)}
          autoSelect
          freeSolo={false}
          renderInput={params => (
            <TextField
              name="system"
              {...params}
              label="System"
              margin="normal"
              helperText="System"
              variant="outlined"
              required={required}
              InputProps={params.InputProps}
            />
          )}
        />
      </FormControl>
    </>
  );
};
