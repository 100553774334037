import React from 'react';
import { Typography} from '@material-ui/core';
import { InfoCard } from '@backstage/core-components';
import Divider from '@material-ui/core/Divider';
import { makeStyles } from '@material-ui/core';
import { Box} from '@material-ui/core';

import DashboardIcon from '@material-ui/icons/Dashboard';
import DynamicFeedIcon from '@material-ui/icons/DynamicFeed';
import BusinessOutlinedIcon from '@material-ui/icons/BusinessOutlined';

const useStyles = makeStyles(() => ({
    title: {
      fontWeight: 'bold',
      fontSize: '18px',
    },
    subtitle: {
      fontSize: '12px',
      marginTop: '5px',
    },
    icon: {
        fontSize: '3.8rem',
        marginRight: '10px',
      },
  }));

const CatalogComponentCard = ({data}:any) => {

    const classes = useStyles();

    return (
      <InfoCard  variant="gridItem">
          <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                  
                    padding: '20px',
                    width:'100%',
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'flex-start',
                      alignItems: 'center',
                      width:'100%',
                    }}
                  >
                    {/* Content for the first inner box */}
                      
                        <DashboardIcon className={classes.icon} />
                      

                    <Box>
                      <Typography variant="body1" className={classes.title}>
                        {data?.componentCount}+
                      </Typography>
                      <Typography variant="caption" className={classes.subtitle}>
                        components added in the last 7 days
                      </Typography>
                    </Box>
                  </Box>
                  <Divider variant='middle'  style={{ width: '100%', marginTop: '10px', marginBottom: '10px', height:'1px' }}/>
              
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'flex-start',
                      alignItems: 'center',
                      width:'100%',
                      marginTop: '10px',
                    }}
                  >
                    {/* Content for the second inner box */}
                    {/* Icon */}
                        <DynamicFeedIcon className={classes.icon}/>
                        
                    <Box>
                      <Typography variant="body1" className={classes.title}>
                        {data?.totalEntities}
                      </Typography>
                      <Typography variant="caption" className={classes.subtitle}>
                        Entities registered in DevHub
                      </Typography>
                    </Box>
                  </Box>
                  <Divider variant='middle'  style={{ width: '100%', marginTop: '10px', marginBottom: '10px', height:'1px' }}/>
              
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'flex-start',
                      alignItems: 'center',
                      marginTop: '10px',
                      width:'100%',
                    }}
                  >
                    {/* Content for the third inner box */}
                    <BusinessOutlinedIcon className={classes.icon}/>
                    
                    <Box>
                      <Typography variant="body1" className={classes.title}>
                        {data?.totalDomains}+
                      </Typography>
                      <Typography variant="caption" className={classes.subtitle}>
                        Domains available to connect with
                      </Typography>
                    </Box>

                  </Box>
                </Box>
              </Box>
          </InfoCard>
    )
}

export default CatalogComponentCard;