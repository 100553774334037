import { Entity } from '@backstage/catalog-model';
import { ConfigApi, createApiRef, FetchApi, IdentityApi } from '@backstage/core-plugin-api';
import { SelectItem } from '@backstage/core-components';

export interface ICostCardService {
  getCostData(
    entity: Entity,
    timePeriod: number,
    opts: any,
  ): Promise<SelectItem[]>;
}

export const costCardApiRef = createApiRef<CostCardService>({
  id: 'plugin.cost.service',
});

export class CostCardService implements ICostCardService {
  private readonly backendUrl: string;
  public readonly frontendUrl: string;
  private readonly fetchApi: FetchApi;
  private identityApi:IdentityApi;


  constructor(options: { config: ConfigApi; fetchApi: FetchApi; identityApi:IdentityApi }) {
    this.fetchApi = options.fetchApi;
    this.backendUrl = options.config.getString('backend.baseUrl');
    this.frontendUrl = options.config.getString('app.baseUrl');
    this.identityApi = options.identityApi;
  }

  async getCostData(entity: Entity, days: number, abrtOpts: any): Promise<any> {
    const url = `${this.backendUrl}/api/proxy/scmservice/Costs?days=${days}`;

    try {

      const {token} = await this.identityApi.getCredentials();
      const response = await this.fetchApi.fetch(url, {
        method: 'POST',
        body: JSON.stringify(entity),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        signal: abrtOpts.signal,
      });
      if (!response.ok) {
        throw Error('could not fetch the cost data');
      }
      const data: any = await response.json();
      return data;
    } catch (err: any) {
      console.log(`Error: ${err.message}`);
    }
  }

  public calculateCostByCluster(data: any[]): any[] {
    const allClusterTotalCost: any[] = [];

    if (data?.length > 0) {
      data.forEach((cluster: any) => {
        const apps = new Set();
        let clusterObj = {
          name: '',
          region: '',
          days: 0,
          apps,
          totalCost: 0,
          unallocatedCost: 0,
          env: '',
          cloudProvider: '',
          channel: '',
          resourceUrl: '',
        };

        clusterObj.name = cluster.cluster;
        clusterObj.region = cluster.region;
        clusterObj.days = cluster.value.length;
        clusterObj.resourceUrl = cluster.resourceUrl;

        const splitName = cluster.cluster
          .substr(0, cluster.cluster.search('-' + cluster.region))
          .split('-');

        clusterObj.env = splitName[splitName.length - 2];
        clusterObj.cloudProvider = splitName[splitName.length - 1];

        const tempName = `${clusterObj.env}-${clusterObj.cloudProvider}-${clusterObj.region}`;
        const channel = cluster.cluster.substr(
          0,
          cluster.cluster.search('-' + tempName),
        );
        clusterObj.channel = channel;

        // a day can have zero app or only __unallocated__ app or a combination of multiple apps
        cluster.value.forEach((day: any) => {
          // iterating apps inside Value => day => cluster
          Object.values(day.Value).forEach((objectValue: any) => {
            if (objectValue.name == '__unallocated__') {
              clusterObj.unallocatedCost += objectValue.totalCost;
            } else {
              clusterObj.totalCost += objectValue.totalCost;
            }
          });
        });
        allClusterTotalCost.push(clusterObj);
      });
      return allClusterTotalCost;
    } else {
      return [];
    }
  }

  public calculateCostByComponent(data: any[]): any[] {
    if (data?.length > 0) {
      const componentsCost: { [key: string]: { totalCost: number } } = {};

      data.forEach((cluster: any) => {
        // a day can have zero app or only __unallocated__ app or a combination of multiple apps
        cluster.value.forEach((day: any) => {
          // iterating apps inside Value => day => component
          Object.values(day.Value).forEach((component: any) => {
            if (componentsCost[component.name]) {
              componentsCost[component.name].totalCost += component.totalCost;
            } else {
              componentsCost[component.name] = {
                totalCost: component.totalCost,
              };
            }
          });
        });
      });

      let arr: any = [];
      Object.keys(componentsCost).map(key => {
        arr.push({ totalCost: componentsCost[key].totalCost, name: key });
      });

      arr.sort(function (a: any, b: any) {
        return b.totalCost - a.totalCost;
      });

      return arr;
    } else {
      return [];
    }
  }
}
