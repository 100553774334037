
import React, { PropsWithChildren } from 'react';
import { Link, makeStyles } from '@material-ui/core';
import HomeIcon from '@material-ui/icons/Home';
import ExtensionIcon from '@material-ui/icons/Extension';
import LibraryBooks from '@material-ui/icons/LibraryBooks';
import CreateComponentIcon from '@material-ui/icons/AddCircleOutline';
import { useApi, configApiRef } from '@backstage/core-plugin-api';
import CategoryIcon from '@material-ui/icons/Category';

import { MyGroupsSidebarItem } from '@backstage/plugin-org';
import GroupIcon from '@material-ui/icons/People'

import LogoFull from './LogoFull';
import LogoIcon from './LogoIcon';
import BuildIcon from '@material-ui/icons/Build';


import {
  Settings as SidebarSettings,
  UserSettingsSignInAvatar,
} from '@backstage/plugin-user-settings';
import { SidebarSearchModal } from '@backstage/plugin-search';
import {
  Sidebar,
  sidebarConfig,
 
  SidebarDivider,
  SidebarGroup,
  SidebarItem,
  SidebarPage,
  SidebarSpace,
  useSidebarOpenState
} from '@backstage/core-components';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import LayersIcon from '@material-ui/icons/Layers';
import { useTheme } from '@material-ui/styles';
import { devToolsAdministerPermission } from '@backstage/plugin-devtools-common';
import { RequirePermission } from '@backstage/plugin-permission-react';

const useSidebarLogoStyles = makeStyles({
  root: {
    width: sidebarConfig.drawerWidthClosed,
    height: 3 * sidebarConfig.logoHeight,
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    marginBottom: -14,
  },
  link: {
    width: sidebarConfig.drawerWidthClosed,
    marginLeft: 24,
  },
});

const SidebarLogo = () => {
  const classes = useSidebarLogoStyles();
  const { isOpen } = useSidebarOpenState();
 
  
return (
  <div className={classes.root}>
    {/* <Link to="/" underline="none" className={classes.link} aria-label="Home"> */}
    <Link underline="none" className={classes.link} aria-label="Home">
    
      {isOpen ? <LogoFull /> : <LogoIcon />}
    </Link>
  </div>
);
};

export const Root = ({ children }: PropsWithChildren<{}>) => {
  const config = useApi(configApiRef);
  const baseTheme:any = useTheme();
  let version = '';
 
  try
  {
    version = `v ${config.getString('RELEASEVERSION')}`;
  }
  catch(e){
    console.log((e as Error).message);
  }
  
  return (
  <SidebarPage>
    <Sidebar>
      <SidebarLogo />
      <SidebarGroup label="Search" icon={<SearchIcon />} to="/search">
        <SidebarSearchModal />
      </SidebarGroup>
      <SidebarDivider />
      <SidebarGroup label="Menu" icon={<MenuIcon />}>
        {/* Global nav, not org-specific */}

        {/* <SidebarItem icon={HomeIcon} to="catalog" text="Home" /> */}
        <MyGroupsSidebarItem
          singularTitle="My Group"
          pluralTitle="My Groups"
          icon={GroupIcon}
        />
        <SidebarItem icon={HomeIcon} to="/" text="Home" />
        <SidebarItem icon={CategoryIcon} to="catalog" text="Catalog" />
        <SidebarItem icon={LayersIcon} to="explore" text="Explore" />
        <SidebarItem icon={ExtensionIcon} to="api-docs" text="APIs" />
        <SidebarItem icon={LibraryBooks} to="docs" text="Docs" />
        <SidebarItem icon={CreateComponentIcon} to="create" text="Create..." />
        {/* End global nav */}
        <SidebarDivider />

      </SidebarGroup>
      <SidebarSpace />
      <a target="_blank" href="https://vonage.slack.com/archives/C0391Q1RTLH" style={{color: `hotpink`, marginLeft: '50px'}}>Feedback</a>
      <SidebarDivider />
     
      <SidebarGroup
        label="Settings"
        icon={<UserSettingsSignInAvatar />}
        to="/settings"
      > 
        <SidebarSettings />
        <RequirePermission
          permission={devToolsAdministerPermission}
          errorPage={<></>}>
          <SidebarItem icon={BuildIcon} to="devtools" text="DevTools" />
        </RequirePermission>
        
        <div style={{color: `${baseTheme?.palette?.background?.default}`, marginLeft: '60px', fontSize: '0.6rem'}}>{version}</div>
      </SidebarGroup>
    </Sidebar>
    {children}
  </SidebarPage>
)};


