import { useApi } from '@backstage/core-plugin-api';
import { catalogApiRef } from '@backstage/plugin-catalog-react';
import { TextField } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { FieldProps, UiSchema } from '@rjsf/utils';
import React, { useCallback, useEffect } from 'react';
import { useSetState } from 'react-use';
import useAsync from 'react-use/lib/useAsync';
export const allowArbitraryValues = (uiSchema: UiSchema): boolean =>
  (uiSchema['ui:options']?.allowArbitraryValues as boolean) ?? true;
export const ChannelPickerExtension = ({
  required,
  rawErrors,
  formData,
  idSchema,
  onChange
}: FieldProps<string>) => {
  
  const catalogApi = useApi(catalogApiRef);
  const [state, setState] = useSetState<any>();
  
  const changed = (state: any) => { 
    setState(state);
    if (state.domain && state.channel && state.env){
      const entity = entities?.items.filter(
            (value) => 
                value.spec?.domain === state.domain 
                && value.spec?.system?.toString().replace(`${state.domain}-`, '') === state.channel 
                && value.metadata.name.replace(`${state.domain}-${state.channel}-`, '') === state.env 
                )[0];
      const annotations = entity?.metadata?.annotations;
      const slug = (annotations)?annotations["github.com/project-slug"]:"";
        onChange("resource:" + state.domain + "~" + state.channel + "~" + state.env + ":github.com/" + slug?.split('/')[0]
                  + "/"+slug?.split("/")[1] + ":" + state.channel + "/" + state.env + ":" + state.class); 
    }

  }
  const { value: entities, loading } = useAsync(() =>
    catalogApi.getEntities({ filter: { kind: "resource", "spec.type": "release-channel-environment" } }),
  );
  let entityRefs = entities?.items.map(e =>  
    {
      return  e.spec?.domain || e.metadata.name.split('-')[0]    ;
    }
  )
   
  const channelRefs = entities?.items.filter((value) => value.spec?.domain === state.domain ).map(e => {
    return e.spec?.system?.toString().replace(`${state.domain}-`, '') || e.metadata.name.split('-')[1];
  })
  const envRefs = entities?.items.filter((value) => value.spec?.domain === state.domain && value.spec?.system === state.domain + "-" + state.channel ).map(e => {
    return e.metadata.name.replace(`${state.domain}-${state.channel}-`, '') || e.metadata.name.split('-')[2];
  })

  const overrideRef = function(name:string):string[] {
    const result = entities?.items.filter((value) => value.spec?.domain === state.domain 
            && value.metadata?.name === state.domain + "-" + state.channel + "-" + name
            ).map(e => {
        let classes = ((e.metadata.annotations)?e.metadata.annotations["vonage.cloud/environment-class"]:"");
        return  classes || name;
        });
    return result??[];
  }; 

  const onSelectDomain = useCallback(
    ( _: any, value: any ) => {
      changed({ domain: value || '', channel:'', env:'', class:'' });      
    },
    [changed],
  );
  const onSelectChannel = useCallback(
    (_: any, value: string | null) => {
      changed({ channel: value || '', domain: state.domain, env:'', class:''  });
    },
    [changed],
  );
  const onSelectEnv = useCallback(
    (_: any, value: string | null) => {
      var envClass =  overrideRef(value??"") || value ;
      changed({ env: value || '',domain: state.domain, channel: state.channel, class: envClass });       
    },
    [changed],
  );
  useEffect(() => {
    
    if (entityRefs?.length === 1) {
      changed({ domain: entityRefs[0] || '' });
      }
  }, [entityRefs, changed]);
   
  return (
    <>
      <FormControl
        margin="normal"
        required={required}
        error={rawErrors && rawErrors.length > 0 && !formData}
      >
        <Autocomplete
          
          disabled={entityRefs?.length === 1}
          id={idSchema?.$id}
          value={(state.domain as string) || ''}
          loading={loading}
          onChange={onSelectDomain}
          options={(entityRefs || []).filter((value, index, self) => self.indexOf(value) === index)}
          autoSelect
          freeSolo={false}
          renderInput={params => (
            <TextField
              name="domain"
              {...params}
              label="Domain"
              margin="normal"
              helperText="The domain to use"
              variant="outlined"
              required={required}
              InputProps={params.InputProps}
            />
          )}
        />
      </FormControl>
      <FormControl
        margin="normal"
        required={required}
        error={rawErrors && rawErrors.length > 0 && !formData}
      >
        <Autocomplete
          disabled={entityRefs?.length === 1}
          id={idSchema?.$id + '-channel'}
          value={(state.channel as string) || ''}
          loading={loading}
          onChange={onSelectChannel}
          options={(channelRefs || []).filter((value, index, self) => self.indexOf(value) === index)}
          autoSelect
          //freeSolo={allowArbitraryValues(uiSchema)}
          renderInput={params => (
            <TextField
              name="channel"
              {...params}
              label="Release Channel"
              margin="normal"
              helperText="The release channel to use"
              variant="outlined"
              required={required}
              InputProps={params.InputProps}
            />
          )}
        />
      </FormControl>
      <FormControl
        margin="normal"
        required={required}
        error={rawErrors && rawErrors.length > 0 && !formData}
      >
        <Autocomplete
          
          disabled={envRefs?.length === 0}
          id={idSchema?.$id + '-env'}
          value={(state.env as string) || ''}
          loading={loading}
          onChange={onSelectEnv}
          options={(envRefs || []).filter((value, index, self) => self.indexOf(value) === index)}
          autoSelect
          //freeSolo={allowArbitraryValues(uiSchema)}
          renderInput={params => (
            <TextField
              name="env"
              {...params}
              label="Release Environment"
              margin="normal"
              helperText="The release environment to use"
              variant="outlined"
              required={required}
              InputProps={params.InputProps}
            />
          )}
        />
      </FormControl>
    </>
  );
};
