import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core';

const useStyles = makeStyles({
  svg: {
    width: 'auto',
    height: 35,
  },
});
//let bodyEl: HTMLElement = document.getElementById('body');
const LogoFull = () => {
  const classes = useStyles();
  const theme = useTheme();

  if(theme.palette.type === 'dark'){

    
    return (
      <svg className={classes.svg} viewBox="0 0 153 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_10_1096)">
      <path d="M21.8721 0L3.97405 17.9097C3.3094 18.5748 3.3094 19.6516 3.97405 20.3167C4.6387 20.9818 5.71479 20.9818 6.37944 20.3167L13.2277 13.464C13.8923 12.7989 14.9684 12.7989 15.6331 13.464C16.2977 14.1291 16.2977 15.2059 15.6331 15.8709L10.9212 20.5859C10.2566 21.251 10.2566 22.3278 10.9212 22.9929C11.5859 23.658 12.662 23.658 13.3266 22.9929L20.6852 15.6295C21.3499 14.9644 22.426 14.9644 23.0906 15.6295C23.7553 16.2945 23.7553 17.3713 23.0906 18.0364L11.0399 30.095C10.3752 30.7601 10.3752 31.8369 11.0399 32.502C11.7045 33.1671 12.7806 33.1671 13.4453 32.502L21.2826 24.6595C21.9473 23.9945 23.0234 23.9945 23.688 24.6595C24.3527 25.3246 24.3527 26.4014 23.688 27.0665L18.4064 32.3515C17.7418 33.0166 17.7418 34.0934 18.4064 34.7585C19.0711 35.4236 20.1472 35.4236 20.8118 34.7585L38.7099 16.8488L36.8702 1.84877L21.8721 0ZM35.7902 16.1718L31.102 20.863L29.5195 9.1924L17.8565 7.60887L22.5447 2.91766L34.3422 4.36659L35.7902 16.1718ZM3.07598 23.7451C2.37177 24.4497 1.23237 24.4497 0.528158 23.7451C-0.176053 23.0404 -0.176053 21.9002 0.528158 21.1956C1.23237 20.4909 2.37177 20.4909 3.07598 21.1956C3.78019 21.9002 3.78019 23.0404 3.07598 23.7451ZM10.2803 33.5036C10.9845 34.2082 10.9845 35.3484 10.2803 36.053C9.57609 36.7577 8.43669 36.7577 7.73248 36.053C7.02827 35.3484 7.02827 34.2082 7.73248 33.5036C8.43669 32.7989 9.57609 32.7989 10.2803 33.5036ZM6.8621 36.924C7.56632 37.6287 7.56632 38.7688 6.8621 39.4735C6.15789 40.1781 5.01849 40.1781 4.31428 39.4735C3.61007 38.7688 3.61007 37.6287 4.31428 36.924C5.01849 36.2193 6.15789 36.2193 6.8621 36.924Z" fill="url(#paint0_linear_10_1096)"/>
      <path d="M66.2711 18.9308C66.2711 24.3693 62.4805 28.3539 57.0349 28.3539H51.002V9.50771H57.0349C62.4805 9.50771 66.2711 13.4923 66.2711 18.9308ZM63.0144 18.9308C63.0144 15.4846 60.7187 12.5769 57.0883 12.5769H54.2053V25.2846H57.0883C60.7187 25.2846 63.0144 22.35 63.0144 18.9308Z" fill="black"/>
      <path d="M80.6593 21.9192C80.6593 22.1616 80.6593 22.6462 80.6326 23.1308H71.1828C71.4231 24.2616 72.7311 25.7154 74.7332 25.7154C76.9488 25.7154 78.2034 24.4231 78.2034 24.4231L79.8051 26.8462C79.8051 26.8462 77.803 28.6769 74.7332 28.6769C70.8892 28.6769 67.9261 25.7154 67.9261 21.9731C67.9261 18.2308 70.6223 15.2692 74.2794 15.2692C77.9365 15.2692 80.6593 17.9885 80.6593 21.9192ZM77.456 20.7885C77.4026 19.6846 76.3882 18.2039 74.2794 18.2039C72.1705 18.2039 71.2896 19.6577 71.1828 20.7885H77.456Z" fill="black"/>
      <path d="M84.53 15.5923L87.7333 22.5385L90.9366 15.5923H94.3802L87.6799 29L80.9796 15.5923H84.53Z" fill="black"/>
      <path d="M107.594 9.50771H110.797V28.3577H107.594V20.25H99.6122V28.3539H96.4089V9.50771H99.6122V17.1808H107.594V9.50771Z" fill="black"/>
      <path d="M114.374 23.8577V15.5923H117.577V22.9692C117.577 24.8538 118.458 25.6615 120.087 25.6615C121.715 25.6615 122.596 24.1 122.596 24.1V15.5923H125.799V28.3577H122.81V26.5C122.81 26.5 121.608 28.6808 119.019 28.6808C116.59 28.6808 114.374 26.9308 114.374 23.8615V23.8577Z" fill="black"/>
      <path d="M129.376 8H132.58V16.9385C132.58 16.9385 133.754 15.2692 136.157 15.2692C139.44 15.2692 142.323 18.0423 142.323 21.9731C142.323 25.9039 139.44 28.6769 136.157 28.6769C133.434 28.6769 132.339 26.6846 132.339 26.6846V28.3539H129.376V8ZM132.58 23.8846C132.58 23.8846 133.487 25.6616 135.596 25.6616C137.705 25.6616 139.066 24.0731 139.066 21.9731C139.066 19.8731 137.758 18.2846 135.596 18.2846C133.434 18.2846 132.58 20.0615 132.58 20.0615V23.8846Z" fill="black"/>
      </g>
      <defs>
      <linearGradient id="paint0_linear_10_1096" x1="37.0502" y1="2.24391" x2="-0.207863" y2="36.3672" gradientUnits="userSpaceOnUse">
      <stop stopColor="#FA7454"/>
      <stop offset="0.484375" stopColor="#D6219C"/>
      <stop offset="1" stopColor="#9941FF"/>
      </linearGradient>
      <clipPath id="clip0_10_1096">
      <rect width="151.655" height="40" fill="black"/>
      </clipPath>
      </defs>
      </svg>
      
  
    ); 
  }
  return (
    <svg className={classes.svg} viewBox="0 0 153 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_10_1096)">
    <path d="M21.8721 0L3.97405 17.9097C3.3094 18.5748 3.3094 19.6516 3.97405 20.3167C4.6387 20.9818 5.71479 20.9818 6.37944 20.3167L13.2277 13.464C13.8923 12.7989 14.9684 12.7989 15.6331 13.464C16.2977 14.1291 16.2977 15.2059 15.6331 15.8709L10.9212 20.5859C10.2566 21.251 10.2566 22.3278 10.9212 22.9929C11.5859 23.658 12.662 23.658 13.3266 22.9929L20.6852 15.6295C21.3499 14.9644 22.426 14.9644 23.0906 15.6295C23.7553 16.2945 23.7553 17.3713 23.0906 18.0364L11.0399 30.095C10.3752 30.7601 10.3752 31.8369 11.0399 32.502C11.7045 33.1671 12.7806 33.1671 13.4453 32.502L21.2826 24.6595C21.9473 23.9945 23.0234 23.9945 23.688 24.6595C24.3527 25.3246 24.3527 26.4014 23.688 27.0665L18.4064 32.3515C17.7418 33.0166 17.7418 34.0934 18.4064 34.7585C19.0711 35.4236 20.1472 35.4236 20.8118 34.7585L38.7099 16.8488L36.8702 1.84877L21.8721 0ZM35.7902 16.1718L31.102 20.863L29.5195 9.1924L17.8565 7.60887L22.5447 2.91766L34.3422 4.36659L35.7902 16.1718ZM3.07598 23.7451C2.37177 24.4497 1.23237 24.4497 0.528158 23.7451C-0.176053 23.0404 -0.176053 21.9002 0.528158 21.1956C1.23237 20.4909 2.37177 20.4909 3.07598 21.1956C3.78019 21.9002 3.78019 23.0404 3.07598 23.7451ZM10.2803 33.5036C10.9845 34.2082 10.9845 35.3484 10.2803 36.053C9.57609 36.7577 8.43669 36.7577 7.73248 36.053C7.02827 35.3484 7.02827 34.2082 7.73248 33.5036C8.43669 32.7989 9.57609 32.7989 10.2803 33.5036ZM6.8621 36.924C7.56632 37.6287 7.56632 38.7688 6.8621 39.4735C6.15789 40.1781 5.01849 40.1781 4.31428 39.4735C3.61007 38.7688 3.61007 37.6287 4.31428 36.924C5.01849 36.2193 6.15789 36.2193 6.8621 36.924Z" fill="url(#paint0_linear_10_1096)"/>
    <path d="M66.2711 18.9308C66.2711 24.3693 62.4805 28.3539 57.0349 28.3539H51.002V9.50771H57.0349C62.4805 9.50771 66.2711 13.4923 66.2711 18.9308ZM63.0144 18.9308C63.0144 15.4846 60.7187 12.5769 57.0883 12.5769H54.2053V25.2846H57.0883C60.7187 25.2846 63.0144 22.35 63.0144 18.9308Z" fill="white"/>
    <path d="M80.6593 21.9192C80.6593 22.1616 80.6593 22.6462 80.6326 23.1308H71.1828C71.4231 24.2616 72.7311 25.7154 74.7332 25.7154C76.9488 25.7154 78.2034 24.4231 78.2034 24.4231L79.8051 26.8462C79.8051 26.8462 77.803 28.6769 74.7332 28.6769C70.8892 28.6769 67.9261 25.7154 67.9261 21.9731C67.9261 18.2308 70.6223 15.2692 74.2794 15.2692C77.9365 15.2692 80.6593 17.9885 80.6593 21.9192ZM77.456 20.7885C77.4026 19.6846 76.3882 18.2039 74.2794 18.2039C72.1705 18.2039 71.2896 19.6577 71.1828 20.7885H77.456Z" fill="white"/>
    <path d="M84.53 15.5923L87.7333 22.5385L90.9366 15.5923H94.3802L87.6799 29L80.9796 15.5923H84.53Z" fill="white"/>
    <path d="M107.594 9.50771H110.797V28.3577H107.594V20.25H99.6122V28.3539H96.4089V9.50771H99.6122V17.1808H107.594V9.50771Z" fill="white"/>
    <path d="M114.374 23.8577V15.5923H117.577V22.9692C117.577 24.8538 118.458 25.6615 120.087 25.6615C121.715 25.6615 122.596 24.1 122.596 24.1V15.5923H125.799V28.3577H122.81V26.5C122.81 26.5 121.608 28.6808 119.019 28.6808C116.59 28.6808 114.374 26.9308 114.374 23.8615V23.8577Z" fill="white"/>
    <path d="M129.376 8H132.58V16.9385C132.58 16.9385 133.754 15.2692 136.157 15.2692C139.44 15.2692 142.323 18.0423 142.323 21.9731C142.323 25.9039 139.44 28.6769 136.157 28.6769C133.434 28.6769 132.339 26.6846 132.339 26.6846V28.3539H129.376V8ZM132.58 23.8846C132.58 23.8846 133.487 25.6616 135.596 25.6616C137.705 25.6616 139.066 24.0731 139.066 21.9731C139.066 19.8731 137.758 18.2846 135.596 18.2846C133.434 18.2846 132.58 20.0615 132.58 20.0615V23.8846Z" fill="white"/>
    </g>
    <defs>
    <linearGradient id="paint0_linear_10_1096" x1="37.0502" y1="2.24391" x2="-0.207863" y2="36.3672" gradientUnits="userSpaceOnUse">
    <stop stopColor="#FA7454"/>
    <stop offset="0.484375" stopColor="#D6219C"/>
    <stop offset="1" stopColor="#9941FF"/>
    </linearGradient>
    <clipPath id="clip0_10_1096">
    <rect width="151.655" height="40" fill="white"/>
    </clipPath>
    </defs>
    </svg>
    

  );
};

export default LogoFull;
