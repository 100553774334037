import { ConfigApi, DiscoveryApi, FetchApi, IdentityApi, createApiRef } from "@backstage/core-plugin-api";

export const homeApiRef = createApiRef<HomeApi>({
    id: 'plugin.home.service',
  });

export class HomeApi {
    
    public readonly frontendUrl: string;
    private readonly fetchApi: FetchApi;
    private discoveryApi:DiscoveryApi;
    private identityApi:IdentityApi;

    
   
    constructor(options: {configApi: ConfigApi; fetchApi: FetchApi; discoveryApi:DiscoveryApi; identityApi:IdentityApi}) {
        this.fetchApi = options.fetchApi;  
        this.frontendUrl = options.configApi.getString("app.baseUrl");
        this.discoveryApi = options.discoveryApi;
        this.identityApi = options.identityApi;
    }

    
    async getCatalogMetrics(daysAgo:number): Promise<any> {
     
        const {token} = await this.identityApi.getCredentials();

        const baseUrl = await this.discoveryApi.getBaseUrl('homeDashboard')
        
        const response = await this.fetchApi.fetch(`${baseUrl}/catalog-metrics/${daysAgo}`, {
            method: 'get',
            headers: new Headers({
                'Authorization': `Bearer ${token}`
            })
        });
        const catalogMetrics:any = await response.json();
         
        return catalogMetrics;
    }

    async getDeploymentMetrics(daysAgo:number): Promise<any> {
     
        const {token} = await this.identityApi.getCredentials();

        const baseUrl = await this.discoveryApi.getBaseUrl('homeDashboard')

        const response = await this.fetchApi.fetch(`${baseUrl}/deployment-metrics/${daysAgo}`, {
            method: 'get',
            headers: new Headers({
                'Authorization': `Bearer ${token}`
            })
        });
        var deployments:any = await response.json();
         
        return deployments;
    }
    
}