import React from 'react';
import { Box, Chip, Typography, Grid} from '@material-ui/core';
import moment from 'moment';
import {
  Link
} from '@backstage/core-components';
import Avatar from '@material-ui/core/Avatar';

const TOP_FIVE = 5;

const getElapsedTime = (start: string) => {
  if(start == 'NA') return 'Not available';
  
  return moment(start).fromNow();
};

const getInitials = (name: string): string => {
  const splitName = name.split('-');

  if (splitName.length >= 2 ) {
    return `${splitName[0].charAt(0)}${splitName[splitName.length-1].charAt(0)}`.toUpperCase();
  }

  return splitName[0].charAt(0).toUpperCase();
}

const selectColor = (number:any) => {
  const hue = number * 137.508; // use golden angle approximation
  return `hsl(${hue},50%,75%)`;
}



const DeploymentListCard = ({data}:{data:any}) =>{

  if(!data){
    return <div style={{marginTop:'20px'}}>Loading...</div>
  } 

  if(Object.keys(data).length == 0){
    return <div style={{marginTop:'20px'}}>🚀 hmm, It seems like our DevHub has been taking a little breather! <br></br><br></br>
    No deployments have taken flight in the last 7 days, so there's nothing new to show off.</div>
  }
  
  if(data?.message){
    return (
        <Typography variant='body2' style={{color: '#fa8128', padding: "12px", marginTop: "4px"}}>
           {data?.message} 
        </Typography>
    )
  }

  return (

    <Grid container spacing={2} style={{marginTop:'10px'}}>
      {
          data?.slice(0,TOP_FIVE).map((cmp:any, index:number) => (
            <Grid item xs={12} key={cmp.id}>
              <Box display="flex" alignItems="center" justifyContent="space-between">

              <Box display="flex" alignItems="center">
                <Avatar variant="rounded" style={{backgroundColor:selectColor(Math.floor(index * 999))}} >{getInitials(cmp?.Component)}</Avatar>
                
                <div >
                    <Typography style={{ marginLeft: '10px' }}>
                      <Link to={`catalog/default/component/${cmp?.Component}`} style={{ fontSize: '0.85rem' }}>{cmp?.Component}</Link>
                    </Typography>
                    
                    <Typography >
                      <Chip label={cmp?.Owner} size="small" style={{ fontSize: '0.55rem' }} />
                    </Typography>
                </div>
              </Box>

              <Typography variant="caption" style={{ fontSize: '0.6rem' }} >
               {getElapsedTime(cmp.CreatedDate)}
              </Typography>
              
              </Box>
            </Grid>
          ))
        }
      </Grid>
  )
}

export default DeploymentListCard