import React from 'react';
import type { ApiEntity } from '@backstage/catalog-model';
import { createApiFactory } from '@backstage/core-plugin-api';
import { ApiDefinitionWidget, apiDocsConfigRef, defaultDefinitionWidgets } from '@backstage/plugin-api-docs';
import YAML from 'js-yaml';

import { RedocStandalone } from 'redoc';
import { useTheme } from '@material-ui/core';
import { relight } from '../themes/lightTheme';
import { redark } from '../themes/darkTheme';

export function apiDocsConfigFactory() {
  return createApiFactory({
  api: apiDocsConfigRef,
  deps: {},
  factory: () => {
    const definitionWidgets = defaultDefinitionWidgets();
    return { 
      getApiDefinitionWidget: (apiEntity: ApiEntity) => {
        if (apiEntity.spec.type === 'openapi') {
          return {
            rawLanguage: 'json',
            title: 'OpenAPI',
            type: 'openapi',
            component: definition => <RedocApiDefinition  definition={YAML.load(definition)} />,
          } as ApiDefinitionWidget
        }
        // fallback to the defaults
        return definitionWidgets.find(d => d.type === apiEntity.spec.type);
      },
    };
  },
});
}

export const RedocApiDefinition = ({ definition }: { definition: any }) => {
  const theme = useTheme();
  const themeVariant = (theme.palette.type == 'light')? relight: redark;
  return (
    <div >
      <RedocStandalone
        spec= {definition}
        options={{
          scrollYOffset: 80,
          theme: {
            ...themeVariant
        },
        }}
      />
    </div>
  );
}
