import React from "react";
import { Grid, makeStyles } from "@material-ui/core";
import { InfoCard, Link } from "@backstage/core-components";
import { Box } from "@material-ui/core";

import MenuBookIcon from "@material-ui/icons/MenuBook";
import LiveHelpIcon from "@material-ui/icons/LiveHelp";
import LibraryBooksIcon from "@material-ui/icons/LibraryBooks";

const useStyles = makeStyles(() => ({
  title: {
    fontSize: '0.95rem',
  }
}));

const LinkCard = () => {

  const classes = useStyles();
  
  return (
    <InfoCard variant="gridItem" title="Quick links">
      <Grid container spacing={2} style={{ marginLeft: "10px" }}>
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              width: "100%",
              gridColumnGap: "10px",
              height: "80px",
            }}
          >
            <MenuBookIcon></MenuBookIcon>
            <Link className={classes.title} to={`create/templates/default/register-catalog`}>
              {" "}
              Register your service
            </Link>
          </Box>
        </Grid>

        <Grid item xs={12} md={6}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              width: "100%",
              gridColumnGap: "10px",
              height: "80px",
            }}
          >
            <LibraryBooksIcon></LibraryBooksIcon>
            <Link className={classes.title} to={`docs/default/resource/vcp-fabric-techdocs`}>
              VCP Fabric Techdoc
            </Link>
          </Box>
        </Grid>

        <Grid item xs={12} md={6}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              width: "100%",
              gridColumnGap: "10px",
              height: "50px",
            }}
          >
            <LiveHelpIcon></LiveHelpIcon>
            <Link className={classes.title} to={`docs/default/resource/vcp-fabric-techdocs/faq`}>
              FAQ's
            </Link>
          </Box>
        </Grid>
      </Grid>
    </InfoCard>
  );
};

export default LinkCard;
