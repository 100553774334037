
import {
  ScmIntegrationsApi,
  scmIntegrationsApiRef,
  ScmAuth,
} from '@backstage/integration-react';
import {
  AnyApiFactory,
  analyticsApiRef,
  configApiRef,
  createApiFactory,
  discoveryApiRef,
  fetchApiRef,
  identityApiRef
} from '@backstage/core-plugin-api';
import { HomeApi, homeApiRef } from './apis/HomeApi';

import { apiRedocPlugin } from "@internal/plugin-api-redoc";
//import { GoogleAnalytics4Api } from './apis/GoogleAnalytics4Api';
import { GoogleAnalytics4 } from '@backstage-community/plugin-analytics-module-ga4';


export const apis: AnyApiFactory[] = [
  createApiFactory({
    api: scmIntegrationsApiRef,
    deps: { configApi: configApiRef },
    factory: ({ configApi }) => ScmIntegrationsApi.fromConfig(configApi),
  }),
  ScmAuth.createDefaultApiFactory(),

  createApiFactory({
    api: homeApiRef,
    deps: { configApi: configApiRef, fetchApi: fetchApiRef, discoveryApi:discoveryApiRef, identityApi:identityApiRef,  },
    factory:({configApi, fetchApi, discoveryApi, identityApi}) => new HomeApi({configApi,fetchApi, discoveryApi, identityApi})
  }),
  
  // createApiFactory({
  //   api: analyticsApiRef,
  //   deps: { configApi: configApiRef, identityApi: identityApiRef },
  //   factory: ({ configApi, identityApi }) => GoogleAnalytics4Api.fromConfig(configApi,{identityApi}),
  // }),

  createApiFactory({
    api: analyticsApiRef,
    deps: { configApi: configApiRef, identityApi: identityApiRef},
    factory: ({ configApi, identityApi }) =>
      GoogleAnalytics4.fromConfig(configApi, {
        identityApi,
        userIdTransform: async (userEntityRef: string): Promise<string> => {
          const ghId = userEntityRef.split('/')[1] ?? userEntityRef;
           return ghId;
         },
      }),
  }),


  (apiRedocPlugin.getApis() as AnyApiFactory[])[0],

];

