import React, { useEffect, useState } from "react";
import {
  HomePageStarredEntities,
  WelcomeTitle,
  HeaderWorldClock,
  ClockConfig,
} from "@backstage/plugin-home";
//import { Content, ContentHeader, Header, Page, InfoCard } from '@backstage/core-components';
import {
  Content,
  ContentHeader,
  Header,
  Page,
} from "@backstage/core-components";
import { HomePageSearchBar } from "@backstage/plugin-search";
//import { HomePageMarkdown } from '@roadiehq/backstage-plugin-home-markdown';
//import { HomePageYourOpenPullRequestsCard, HomePageRequestedReviewsCard } from '@roadiehq/backstage-plugin-github-pull-requests';
import { homeApiRef } from "../../apis/HomeApi";
import { useApi } from "@backstage/core-plugin-api";
import Grid from "@material-ui/core/Grid";
import CatalogComponentCard from "./components/CatalogComponentCard";
import DeploymentComponentCard from "./components/DeploymentComponentCard";
import { Typography } from "@material-ui/core";
import LinkCard from "./components/LinkCard";

const clockConfigs: ClockConfig[] = [
  {
    label: "California",
    timeZone: "PST",
  },
  {
    label: "New Jersey",
    timeZone: "America/New_York",
  },
  {
    label: "London",
    timeZone: "Europe/London",
  },
  {
    label: "Tel Aviv",
    timeZone: "Asia/Jerusalem",
  },
  {
    label: "Bengaluru",
    timeZone: "Asia/Kolkata",
  },
];

const timeFormat: Intl.DateTimeFormatOptions = {
  hour: "2-digit",
  minute: "2-digit",
  hour12: false,
};

const HomePageComponent = () => {
  const homeApi = useApi(homeApiRef);
  const [catalogState, setCatalogState] = useState<any>();
  const [deploymentState, setDeploymentState] = useState<any>();

  useEffect(() => {
    const getCatalogMetrics = async () => {
      const catalogResponse = await homeApi.getCatalogMetrics(7);
      const deploymentResponse = await homeApi.getDeploymentMetrics(7);

      setCatalogState(catalogResponse);
      setDeploymentState(deploymentResponse);
    };

    getCatalogMetrics();
  }, []);
  return (
    <Page themeId="home">
      <Header title={<WelcomeTitle language={['English', 'Hebrew', 'Spanish', 'German', 'Polish', 'Malay']} />} pageTitleOverride="Home">
        <HeaderWorldClock
          clockConfigs={clockConfigs}
          customTimeFormat={timeFormat}
        />
      </Header>

      <Content>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <HomePageSearchBar />
          </Grid>

          <div
            style={{ marginLeft: "15px", marginTop: "20px", fontSize: "22px" }}
          >
            <ContentHeader
              titleComponent={<Typography variant="h5">Org Metrics</Typography>}
              description="These are high level metrics at Vonage level, few more tiles are coming soon!"
            />
          </div>
          <Grid container spacing={2} style={{ marginLeft: "10px" }}>
            <Grid item xs={12} md={6}>
              <div style={{ height: "500px" }}>
                <CatalogComponentCard data={catalogState} />
              </div>
            </Grid>

            <Grid item xs={12} md={6}>
              <div style={{ height: "500px" }}>
                <DeploymentComponentCard data={deploymentState} />
              </div>
            </Grid>

            <Grid item xs={12} md={6}>
              <div style={{ height: "300px" }}>
                <LinkCard />
              </div>
            </Grid>
          </Grid>

          <div style={{ marginLeft: "15px", marginTop: "20px" }}>
            <ContentHeader
              titleComponent={<Typography variant="h5">My Wall</Typography>}
              description="Things which you Owned or have interest in it, few more tiles are coming soon!"
            />
          </div>

          <Grid container spacing={2} style={{ marginLeft: "10px" }}>
            <Grid item xs={12} md={4}>
              <div style={{ height: "300" }}>
                <HomePageStarredEntities />
              </div>
            </Grid>
            <Grid item xs={12} md={4}>
              <div style={{ height: "300" }}>
                {/* <HomePageRequestedReviewsCard /> */}
              </div>
            </Grid>
            <Grid item xs={12} md={4}>
              <div style={{ height: "300px" }}>
                {/* <HomePageYourOpenPullRequestsCard /> */}
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Content>
    </Page>
  );
};

export const HomePage = <HomePageComponent />;
