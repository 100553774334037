import { createPermission } from '@backstage/plugin-permission-common';

/**
 * @public
 */
export const routeAccessPermission = createPermission({
  name: 'route.access.allow',
  attributes: { action: 'read' },
});

/**
 * List of all RouteAccess permissions
 *
 * @public
 */
export const commonPermissions = [
    routeAccessPermission,
];