import React from "react";
import { Grid } from "@material-ui/core";
import {
  EntityApiDefinitionCard,
  EntityConsumedApisCard,
  EntityConsumingComponentsCard,
  EntityHasApisCard,
  EntityProvidedApisCard,
  EntityProvidingComponentsCard,
} from "@backstage/plugin-api-docs";
import {
  EntityAboutCard,
  EntityDependsOnComponentsCard,
  EntityDependsOnResourcesCard,
  EntityHasComponentsCard,
  EntityHasResourcesCard,
  EntityHasSystemsCard,
  EntityLayout,
  EntityLinksCard,
  EntitySwitch,
  EntityOrphanWarning,
  EntityProcessingErrorsPanel,
  isComponentType,
  isKind,
  hasCatalogProcessingErrors,
  isOrphan,
  hasRelationWarnings,
  EntityRelationWarning,
} from "@backstage/plugin-catalog";
import {
  EntityUserProfileCard,
  EntityGroupProfileCard,
  EntityMembersListCard,
  EntityOwnershipCard,
} from "@backstage/plugin-org";
import { EntitySonarQubeCard } from "@backstage-community/plugin-sonarqube";
import { EntityTechdocsContent } from "@backstage/plugin-techdocs";
import { EntityGithubPullRequestsContent } from "@roadiehq/backstage-plugin-github-pull-requests";

import {
  EntityCostComponentCard,
  EntityCostClusterCard,
  EntityCostOverviewTile,
} from "@internal/plugin-kube-cost";

import {
  EntityArgoHealthCard,
  EntityArgoHealthPage,
} from "@internal/plugin-argo-health";

import { GithubSecretsPage } from "@internal/plugin-github-secrets";
//import { GlobalDNSPage } from "@internal/plugin-gdns";

import {
  Direction,
  EntityCatalogGraphCard,
} from "@backstage/plugin-catalog-graph";
import {
  RELATION_API_CONSUMED_BY,
  RELATION_API_PROVIDED_BY,
  RELATION_CONSUMES_API,
  RELATION_DEPENDENCY_OF,
  RELATION_DEPENDS_ON,
  RELATION_HAS_PART,
  RELATION_PART_OF,
  RELATION_PROVIDES_API,
} from "@backstage/catalog-model";

import { TechDocsAddons } from "@backstage/plugin-techdocs-react";
import { ReportIssue } from "@backstage/plugin-techdocs-module-addons-contrib";
import { EntityDeploymentsCard } from "@internal/plugin-devhub-deployments";

const techdocsContent = (
  <EntityTechdocsContent>
    <TechDocsAddons>
      <ReportIssue />
    </TechDocsAddons>
  </EntityTechdocsContent>
);

const entityWarningContent = (
  <>
    <EntitySwitch>
      <EntitySwitch.Case if={isOrphan}>
        <Grid item xs={12}>
          <EntityOrphanWarning />
        </Grid>
      </EntitySwitch.Case>
    </EntitySwitch>

    <EntitySwitch>
      <EntitySwitch.Case if={hasRelationWarnings}>
        <Grid item xs={12}>
          <EntityRelationWarning />
        </Grid>
      </EntitySwitch.Case>
    </EntitySwitch>

    <EntitySwitch>
      <EntitySwitch.Case if={hasCatalogProcessingErrors}>
        <Grid item xs={12}>
          <EntityProcessingErrorsPanel />
        </Grid>
      </EntitySwitch.Case>
    </EntitySwitch>
  </>
);

const codeQualityContent = (
  <Grid item>
    <EntitySonarQubeCard />
  </Grid>
);

const overviewContent = (
  <Grid container spacing={3} direction="row" alignItems="stretch">
    {entityWarningContent}

    <Grid item xs={12} sm={6} md={6}>
      <EntityAboutCard variant="gridItem" />
    </Grid>

    <Grid item xs={12} sm={6} md={6}>
      {<EntityArgoHealthCard />}
    </Grid>

    <Grid item xs={12} sm={6} md={6}>
      <EntityCatalogGraphCard />
    </Grid>

    <Grid item xs={12} sm={6} md={6}>
      {<EntityCostOverviewTile />}
    </Grid>

    <Grid item xs={12} sm={6} md={6}>
      {codeQualityContent}
    </Grid>
  </Grid>
);

const gitHubDeploymentsContent = (
  <Grid container spacing={3} alignItems="stretch">
    <Grid item xs={12}>
      <EntityDeploymentsCard />
    </Grid>
  </Grid>
);

const kubeCostComponentContent = (
  <Grid container spacing={3} alignItems="stretch">
    <Grid item xs={12}>
      <EntityCostComponentCard />
    </Grid>
  </Grid>
);

const kubeCostClusterContent = (
  <Grid container spacing={3} alignItems="stretch">
    <Grid item xs={12}>
      <EntityCostClusterCard />
    </Grid>
  </Grid>
);

const argoDetailContent = (
  <Grid container spacing={3} alignItems="stretch">
    <Grid item xs={12}>
      <EntityArgoHealthPage />
    </Grid>
  </Grid>
);



const githubSecretsContent = (
  <Grid container spacing={3} alignItems="stretch">
    <Grid item xs={12}>
      <GithubSecretsPage />
    </Grid>
  </Grid>
);

// const githubGlobalDNSContent = (
//   <Grid container spacing={3} alignItems="stretch">
//     <Grid item xs={12}>
//       <GlobalDNSPage />
//     </Grid>
//   </Grid>
// );

const gitHubPullRequestContent = <EntityGithubPullRequestsContent />;

const serviceEntityPage = (
  <EntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      {overviewContent}
    </EntityLayout.Route>

    <EntityLayout.Route path="/api" title="API">
      <Grid container spacing={3} alignItems="stretch">
        <Grid item md={6}>
          <EntityProvidedApisCard />
        </Grid>
        <Grid item md={6}>
          <EntityConsumedApisCard />
        </Grid>
      </Grid>
    </EntityLayout.Route>

    <EntityLayout.Route path="/dependencies" title="Dependencies">
      <Grid container spacing={3} alignItems="stretch">
        <Grid item md={6}>
          <EntityDependsOnComponentsCard variant="gridItem" />
        </Grid>
        <Grid item md={6}>
          <EntityDependsOnResourcesCard variant="gridItem" />
        </Grid>
      </Grid>
    </EntityLayout.Route>

    <EntityLayout.Route path="/docs" title="Docs">
      {techdocsContent}
    </EntityLayout.Route>

    <EntityLayout.Route path="/deployments" title="Deployments">
      {gitHubDeploymentsContent}
    </EntityLayout.Route>

    <EntityLayout.Route path="/cost" title="Cost">
      {kubeCostComponentContent}
    </EntityLayout.Route>

    <EntityLayout.Route path="/health" title="Health">
      {argoDetailContent}
    </EntityLayout.Route>


    <EntityLayout.Route path="/secrets" title="Secrets">
      {githubSecretsContent}
    </EntityLayout.Route>

    {/* <EntityLayout.Route path="/gdns" title="GlobalDNS">
      {githubGlobalDNSContent}
    </EntityLayout.Route> */}


    <EntityLayout.Route path="/pull-requests" title="Pull Requests">
      {gitHubPullRequestContent}
    </EntityLayout.Route>
  </EntityLayout>
);

const libraryEntityPage = (
  <EntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      {overviewContent}
    </EntityLayout.Route>

    <EntityLayout.Route path="/api" title="API">
      <Grid container spacing={3} alignItems="stretch">
        <Grid item md={6}>
          <EntityProvidedApisCard />
        </Grid>
        <Grid item md={6}>
          <EntityConsumedApisCard />
        </Grid>
      </Grid>
    </EntityLayout.Route>

    <EntityLayout.Route path="/dependencies" title="Dependencies">
      <Grid container spacing={3} alignItems="stretch">
        <Grid item md={6}>
          <EntityDependsOnComponentsCard variant="gridItem" />
        </Grid>
        <Grid item md={6}>
          <EntityDependsOnResourcesCard variant="gridItem" />
        </Grid>
      </Grid>
    </EntityLayout.Route>

    <EntityLayout.Route path="/docs" title="Docs">
      {techdocsContent}
    </EntityLayout.Route>

    <EntityLayout.Route path="/pull-requests" title="Pull Requests">
      <EntityGithubPullRequestsContent />
    </EntityLayout.Route>
  </EntityLayout>
);

const buildComponentEntityPage = (
  <EntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      {overviewContent}
    </EntityLayout.Route>

    <EntityLayout.Route path="/dependencies" title="Dependencies">
      <Grid container spacing={3} alignItems="stretch">
        <Grid item md={6}>
          <EntityDependsOnComponentsCard variant="gridItem" />
        </Grid>
        <Grid item md={6}>
          <EntityDependsOnResourcesCard variant="gridItem" />
        </Grid>
      </Grid>
    </EntityLayout.Route>

    <EntityLayout.Route path="/docs" title="Docs">
      {techdocsContent}
    </EntityLayout.Route>

    <EntityLayout.Route path="/pull-requests" title="Pull Requests">
      <EntityGithubPullRequestsContent />
    </EntityLayout.Route>
  </EntityLayout>
);

const websiteEntityPage = (
  <EntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      {overviewContent}
    </EntityLayout.Route>

    <EntityLayout.Route path="/dependencies" title="Dependencies">
      <Grid container spacing={3} alignItems="stretch">
        <Grid item md={6}>
          <EntityDependsOnComponentsCard variant="gridItem" />
        </Grid>
        <Grid item md={6}>
          <EntityDependsOnResourcesCard variant="gridItem" />
        </Grid>
      </Grid>
    </EntityLayout.Route>

    <EntityLayout.Route path="/docs" title="Docs">
      {techdocsContent}
    </EntityLayout.Route>

    <EntityLayout.Route path="/deployments" title="Deployments">
      {gitHubDeploymentsContent}
    </EntityLayout.Route>

    <EntityLayout.Route path="/cost" title="Cost">
      {kubeCostComponentContent}
    </EntityLayout.Route>


    <EntityLayout.Route path="/secrets" title="Secrets">
      {githubSecretsContent}
    </EntityLayout.Route>

    {/* <EntityLayout.Route path="/gdns" title="GlobalDNS">
      {githubGlobalDNSContent}
    </EntityLayout.Route> */}

    <EntityLayout.Route path="/health" title="Health">
      {argoDetailContent}
    </EntityLayout.Route>

    <EntityLayout.Route path="/pull-requests" title="Pull Requests">
      <EntityGithubPullRequestsContent />
    </EntityLayout.Route>
  </EntityLayout>
);

/**
 * NOTE: This page is designed to work on small screens such as mobile devices.
 * This is based on Material UI Grid. If breakpoints are used, each grid item must set the `xs` prop to a column size or to `true`,
 * since this does not default. If no breakpoints are used, the items will equitably share the available space.
 * https://material-ui.com/components/grid/#basic-grid.
 */
const clusterPage = (
  <EntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      {overviewContent}
    </EntityLayout.Route>

    <EntityLayout.Route path="/docs" title="Docs">
      {techdocsContent}
    </EntityLayout.Route>

    <EntityLayout.Route path="/cost" title="Cost">
      {kubeCostClusterContent}
    </EntityLayout.Route>


    <EntityLayout.Route path="/secrets" title="Secrets">
      {githubSecretsContent}
    </EntityLayout.Route>

    {/* <EntityLayout.Route path="/gdns" title="GlobalDNS">
      {githubGlobalDNSContent}
    </EntityLayout.Route> */}

    <EntityLayout.Route path="/health" title="Health">
      {argoDetailContent}
    </EntityLayout.Route>

    <EntityLayout.Route path="/pull-requests" title="Pull Requests">
      <EntityGithubPullRequestsContent />
    </EntityLayout.Route>
  </EntityLayout>
);

const defaultEntityPage = (
  <EntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      {overviewContent}
    </EntityLayout.Route>

    <EntityLayout.Route path="/docs" title="Docs">
      {techdocsContent}
    </EntityLayout.Route>

    <EntityLayout.Route path="/pull-requests" title="Pull Requests">
      <EntityGithubPullRequestsContent />
    </EntityLayout.Route>
  </EntityLayout>
);

const componentPage = (
  <EntitySwitch>
    <EntitySwitch.Case if={isComponentType("service")}>
      {serviceEntityPage}
    </EntitySwitch.Case>

    <EntitySwitch.Case if={isComponentType("website")}>
      {websiteEntityPage}
    </EntitySwitch.Case>

    <EntitySwitch.Case if={isComponentType("library")}>
      {libraryEntityPage}
    </EntitySwitch.Case>

    <EntitySwitch.Case if={isComponentType("gradle-plugin")}>
      {buildComponentEntityPage}
    </EntitySwitch.Case>

    <EntitySwitch.Case if={isComponentType("terraform-module")}>
      {buildComponentEntityPage}
    </EntitySwitch.Case>

    <EntitySwitch.Case>{defaultEntityPage}</EntitySwitch.Case>
  </EntitySwitch>
);

const apiPage = (
  <EntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      <Grid container spacing={3}>
        {entityWarningContent}
        <Grid item md={6}>
          <EntityAboutCard />
        </Grid>
        <Grid item md={6} xs={12}>
          <EntityCatalogGraphCard variant="gridItem" height={400} />
        </Grid>
        <Grid container item md={12}>
          <Grid item md={6}>
            <EntityProvidingComponentsCard />
          </Grid>
          <Grid item md={6}>
            <EntityConsumingComponentsCard />
          </Grid>
        </Grid>
      </Grid>
    </EntityLayout.Route>

    <EntityLayout.Route path="/definition" title="Definition">
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <EntityApiDefinitionCard />
        </Grid>
      </Grid>
    </EntityLayout.Route>
  </EntityLayout>
);

const userPage = (
  <EntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      <Grid container spacing={3}>
        {entityWarningContent}
        <Grid item xs={12} md={6}>
          <EntityUserProfileCard variant="gridItem" />
        </Grid>
        <Grid item xs={12} md={6}>
          <EntityOwnershipCard variant="gridItem" />
        </Grid>
      </Grid>
    </EntityLayout.Route>
  </EntityLayout>
);

const groupPage = (
  <EntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      <Grid container spacing={3}>
        {entityWarningContent}
        <Grid item xs={12} md={6}>
          <EntityGroupProfileCard variant="gridItem" />
        </Grid>
        <Grid item xs={12} md={6}>
          <EntityOwnershipCard variant="gridItem" />
        </Grid>
        <Grid item xs={12}>
          <EntityMembersListCard />
        </Grid>
        <Grid item xs={12} md={6}>
          <EntityLinksCard />
        </Grid>
      </Grid>
    </EntityLayout.Route>
    
  </EntityLayout>
);

const systemPage = (
  <EntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      <Grid container spacing={3} alignItems="stretch">
        {entityWarningContent}
        <Grid item md={6}>
          <EntityAboutCard variant="gridItem" />
        </Grid>
        <Grid item md={6} xs={12}>
          <EntityCatalogGraphCard variant="gridItem" height={400} />
        </Grid>
        <Grid item md={4} xs={12}>
          <EntityLinksCard />
        </Grid>
        <Grid item md={8}>
          <EntityHasComponentsCard variant="gridItem" />
        </Grid>
        <Grid item md={6}>
          <EntityHasApisCard variant="gridItem" />
        </Grid>
        <Grid item md={6}>
          <EntityHasResourcesCard variant="gridItem" />
        </Grid>
      </Grid>
    </EntityLayout.Route>
    <EntityLayout.Route path="/diagram" title="Diagram">
      <EntityCatalogGraphCard
        variant="gridItem"
        direction={Direction.TOP_BOTTOM}
        title="System Diagram"
        height={700}
        relations={[
          RELATION_PART_OF,
          RELATION_HAS_PART,
          RELATION_API_CONSUMED_BY,
          RELATION_API_PROVIDED_BY,
          RELATION_CONSUMES_API,
          RELATION_PROVIDES_API,
          RELATION_DEPENDENCY_OF,
          RELATION_DEPENDS_ON,
        ]}
        unidirectional={false}
      />
    </EntityLayout.Route>
  </EntityLayout>
);

const domainPage = (
  <EntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      <Grid container spacing={3} alignItems="stretch">
        {entityWarningContent}
        <Grid item md={6}>
          <EntityAboutCard variant="gridItem" />
        </Grid>
        <Grid item md={6} xs={12}>
          <EntityCatalogGraphCard variant="gridItem" height={400} />
        </Grid>
        <Grid item md={6}>
          <EntityHasSystemsCard variant="gridItem" />
        </Grid>
      </Grid>
    </EntityLayout.Route>
  </EntityLayout>
);

export const entityPage = (
  <EntitySwitch>
    <EntitySwitch.Case
      if={(entity) => {
        if (
          entity.kind.toLocaleLowerCase() === "resource" &&
          String(entity?.spec?.type).toLocaleLowerCase() == "cerebro-cluster"
        ) {
          return true;
        }
        return false;
      }}
      children={clusterPage}
    />
    <EntitySwitch.Case if={isKind("component")} children={componentPage} />
    <EntitySwitch.Case if={isKind("api")} children={apiPage} />
    <EntitySwitch.Case if={isKind("group")} children={groupPage} />
    <EntitySwitch.Case if={isKind("user")} children={userPage} />
    <EntitySwitch.Case if={isKind("system")} children={systemPage} />
    <EntitySwitch.Case if={isKind("domain")} children={domainPage} />

    <EntitySwitch.Case>{defaultEntityPage}</EntitySwitch.Case>
  </EntitySwitch>
);