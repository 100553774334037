import {
  createApiFactory,
  createComponentExtension,
  createPlugin,
  configApiRef,
  fetchApiRef,
  identityApiRef
} from '@backstage/core-plugin-api';

import { costCardApiRef, CostCardService } from './api/CostCardService';


export const kubeCostPlugin = createPlugin({
  id: 'kube-cost',
  apis: [
    createApiFactory({
      api: costCardApiRef,
      deps:{
        config: configApiRef,
        fetchApi: fetchApiRef,
        identityApi:identityApiRef,
      },
      factory:({config, fetchApi, identityApi}) => new CostCardService({config,fetchApi, identityApi})
    })
  ],
});

export const EntityCostComponentCard = kubeCostPlugin.provide(
  createComponentExtension({
    component: {
      lazy: () =>
        import('./components/CostCard/ComponentView/CostDetailsCard').then(
          m => m.CostDetailsCard,
        ),
    },
  }),
);

export const EntityCostClusterCard = kubeCostPlugin.provide(
  createComponentExtension({
    component: {
      lazy: () =>
      import('./components/CostCard/ClusterView/ClusterView').then(
          m => m.ClusterView,
        ),
    },
  }),
);


export const EntityCostOverviewTile = kubeCostPlugin.provide(
  createComponentExtension({
    component: {
      lazy: () =>
        import('./components/CostCard').then(
          m => m.CostOverviewTile,
        ),
    },
  }),
);
