import { Entity } from "@backstage/catalog-model";
import { ConfigApi, createApiRef, FetchApi,IdentityApi } from "@backstage/core-plugin-api";
import { SelectItem } from "@backstage/core-components";
import { ComponentInfo, TimelineHistoryModel } from "../models/DetailModel";



export interface IArgoHealthService{
    getEntityHealth(entity: Entity, opts:any): Promise<SelectItem[]>;
}

export const argoHealthApiRef = createApiRef<ArgoHealthService>({
    id: 'plugin.argohealth.service',
  });

export class ArgoHealthService implements IArgoHealthService {
    
    public readonly backendUrl: string;
    public readonly frontendUrl: string;
    private readonly fetchApi: FetchApi;
    private identityApi:IdentityApi;

    
    public static GitHubProjectSlugAnnotationKey: string = "github.com/project-slug";

    constructor(options: {config: ConfigApi; fetchApi: FetchApi; identityApi:IdentityApi}) {
        this.fetchApi = options.fetchApi;  
        this.backendUrl = options.config.getString("backend.baseUrl");
        this.frontendUrl = options.config.getString("app.baseUrl");
        this.identityApi = options.identityApi;
    }

    async getToken(){
        const {token} = await this.identityApi.getCredentials();
        return token
    }
    async getSemVerHistoryList(org:string, repo:string, sha: string[], abrtOpts:any): Promise<any> {
        
        try{
            
            const {token} = await this.identityApi.getCredentials();

            const url = `${this.backendUrl}/api/proxy/scmservice/DeploymentStatus/SemVerList/?organization=${org}&repo=${repo}&SHA=${sha}`

            const response = await this.fetchApi.fetch(url, {
                method: "POST", 
                body: JSON.stringify(sha), 
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': `Bearer ${token}`
                },
                signal: abrtOpts.signal
        });

            if(!response.ok){
                return {message:` SHA Not Available `};
            }

            const data: any = await response.json();
            return data;  
           
        }catch(e){
            console.log(`Could not able to fetach Deployment health data ${e}`)
        }
        
    }

    transformSemVer(data:any){

       const historyList = data.map((data:any)=>{

        const url = data.value.pullRequestUrl;
        const segments = url.split("/").slice(-4);

        //segment[0] = org, [1]= repo, [2]=pulls, [3]= pr-no 
        const pr = `https://github.com/${segments[0]}/${segments[1]}/pull/${segments[3]}`;

        const historyModel:TimelineHistoryModel = {
            channel:data.value.channel,
            
            component:data.value.component,
            createdDate:data.value.createdDate,
            environment:data.value.environment,

            pullRequestUrl:pr,
            pullRequestSHA:data.value.pullRequestSHA,
            semVer:data.value.semVer,
            status:data.value.metadata.status
        }

        return historyModel

        })


        return historyList;

    }

    async getEntityHealth(entity: Entity, abrtOpts:any): Promise<any> {
        
        const url = `${this.backendUrl}/api/proxy/scmservice/DeploymentStatus`;
        
        try{
            const {token} = await this.identityApi.getCredentials();

            const response = await this.fetchApi.fetch(url, {
                    method: "POST", 
                    body: JSON.stringify(entity), 
                    headers: {
                        "Content-Type": "application/json",
                        'Authorization': `Bearer ${token}`
                    },
                    signal: abrtOpts.signal
                });
                
                if(!response.ok){
                    return {message:` Deployment Health data not available for ${entity.metadata?.name??"entity"}`};
                }

                return await response.json();
           
        }catch(e){
            console.log(`Could not able to fetch Deployment health data ${e}`)
        }
        
    }

    transform(data:any[], entity:any):ComponentInfo[]{

        const componentsInfo:ComponentInfo[] =  [];
        // if kind is Resource then this is a cluster call
        if(entity.kind.toLocaleLowerCase() === 'resource' && entity?.spec?.type?.toLocaleLowerCase() === 'cerebro-cluster'){

            data.map(cluster => {
               
                cluster.value.map((service:any) => {
                    const componentInfo:ComponentInfo =  {name:'', region:'', channel:'', env:'', health:'', lastSynced:'', resources:[], lastDeployed:'', semverHistory:[]};
                    
                   
                    const path = service.spec?.source?.path?.split('/');
                    componentInfo.channel = path ? path[0] :  '';
                    componentInfo.env = path ? path[1] :  '';
                    componentInfo.region = cluster.region;
                    componentInfo.lastSynced = service.status?.operationState?.finishedAt ?? 'NA';
                    componentInfo.health = service.status?.health?.status ?? 'Unknown';
                    componentInfo.name = service.metadata.name ?? '';

                    componentInfo.resources = service.status.resources;
                    componentInfo.lastDeployed = service.status.history?.[service.status.history?.length -1];
                    componentInfo.semverHistory = service.status.history;

                    componentsInfo.push(componentInfo);
                })
            })
            return componentsInfo;
        }
        else{
                // Kind is component type
                data.map(cluster => {
                    const componentInfo:ComponentInfo =  {name:'', region:'', channel:'', env:'', health:'', lastSynced:'', resources:[], lastDeployed:'', semverHistory:[]};
                    let name =  cluster.cluster;
                    // this could be multiple in case of cluster entity
                    cluster.value.map((service:any) => {
                        
                        const path = service.spec?.source?.path?.split('/');
                        componentInfo.channel = path ? path[0] :  '';
                        componentInfo.env = path ? path[1] :  '';
                        componentInfo.region = cluster.region;
                        componentInfo.lastSynced = service.status?.operationState?.finishedAt ?? 'NA';
                        componentInfo.health = service.status?.health?.status ?? 'Unknown';
                        name = name.substr(0, name.search("-"+componentInfo.env));
                        componentInfo.name = name ?? '';
                        componentInfo.resources = service.status.resources;
                        componentInfo.lastDeployed = service.status.history?.[service.status.history?.length -1];
                        componentInfo.semverHistory = service.status.history;
                    })
                    componentsInfo.push(componentInfo);
                })
                return componentsInfo;
            }
    }
}