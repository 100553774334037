import React, { useState, createContext } from 'react';

type TokenType = { token: String | undefined };
type AuthCtxState = {
  authState: TokenType,
  setToken: (token: String) => void;
};

const initialState = { authState: { token: undefined }, setToken: () => {}};
const AuthContext = createContext<AuthCtxState>(initialState);

export function AuthProvider({ children }: {children: JSX.Element}): JSX.Element {
  const [authState, setAuthState] = useState<TokenType>({token: undefined});

  function setToken(token: String) {
    setAuthState({token});
  }

  return (
    <AuthContext.Provider value={{setToken, authState}}>
      {children}
    </AuthContext.Provider>
  )
}

