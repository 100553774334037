import {
  createApiFactory,
  createComponentExtension,
  createPlugin,
  configApiRef,
  fetchApiRef,
  identityApiRef
} from '@backstage/core-plugin-api';

import { deploymentsApiRef, DeploymentService } from './api/DeploymentService';

export const devhubDeploymentsPlugin = createPlugin({
  id: 'devhub-deployments',
  apis: [
    createApiFactory({
      api: deploymentsApiRef,
      deps:{
        config: configApiRef,
        fetchApi: fetchApiRef,
        identityApi:identityApiRef,
      },
      factory:({config, fetchApi, identityApi}) => new DeploymentService({config,fetchApi,identityApi})
    })
  ],
});

export const EntityDeploymentsCard = devhubDeploymentsPlugin.provide(
  createComponentExtension({
    component: {
      lazy: () =>
        import('./components/DeploymentsCard').then(
          m => m.DeploymentsCard,
        ),
    },
  }),
);

