import {
  createApiFactory,
  createPlugin,
  configApiRef,
  fetchApiRef,
  createComponentExtension,
  identityApiRef,
} from "@backstage/core-plugin-api";

import {
  githubSecretsApiRef,
  GithubSecretsService,
} from "./api/GithubSecretsService";

export const githubSecretsPlugin = createPlugin({
  id: "github-secrets",
  apis: [
    createApiFactory({
      api: githubSecretsApiRef,
      deps: {
        config: configApiRef,
        fetchApi: fetchApiRef,
        identityApi:identityApiRef,

      },
      factory: ({ config, fetchApi, identityApi }) =>
        new GithubSecretsService({ config, fetchApi,identityApi }),
    }),
  ],
});

export const GithubSecretsPage = githubSecretsPlugin.provide(
  createComponentExtension({
    component: {
      lazy: () =>
        import("./components/GithubSecretsPage").then(
          (m) => m.GithubSecretsPage
        ),
    },
  })
);
