import React from "react";
import { Content, ContentHeader, Header, Page } from "@backstage/core-components";
import { Grid, Link, Typography } from "@material-ui/core";

export const NoTeamErrorPage = () => {
  return (
    <Page themeId="home">
    <Header title={<div>Time to Find Your Work Family!</div>} pageTitleOverride="Home">
    </Header>

    <Content>
    <Grid container spacing={4}>
        <Grid item xs={12}>
          <div style={{ marginLeft: "15px", marginTop: "20px" }}>
          <Typography variant="body1" style={{ marginTop: "10px" }}>
          Happy to see you here at DevHub! It looks like <b>you're not part of any team in the Vonage organization or the reader group in Okta.</b> 
          <br/><br/> No worries! To join the DevHub happy family, choose one of the options below and get ready to dive in!
          </Typography>
             
           
          </div>
        </Grid>

        <Grid item xs={6}>
          <div style={{ marginLeft: "15px", marginTop: "20px" }}>
            <ContentHeader
              titleComponent={<Typography variant="h6">Join a Team</Typography>}>
              <>
                  <Typography variant="body1" style={{ marginTop: "10px" }}>
                    - Become part of a team in the Vonage or Vonage-Technology organization.
                  </Typography>
                  <Typography variant="body1" style={{ marginTop: "10px" }}>
                    - Raise a ticket via the GitOps process.
                  </Typography>
                  <Typography variant="body1" style={{ marginTop: "10px" }}>
                    - Submit a PR to add yourself to an existing team or request a new one here: 
                    <Link href="https://github.com/vonage-technology/vcp-fabric-okta-sync/tree/main/github-okta-group-sync-requests" target="_blank">
                      GitHub Repository
                    </Link>.
                  </Typography>
                  <Typography variant="body1" style={{ marginTop: "10px" }}>
                    - The PR should be approved by the team owner.
                  </Typography>
                </>
            </ContentHeader>
          </div>
        </Grid>

        <Grid item xs={6}>
          <div style={{ marginLeft: "15px", marginTop: "20px" }}>
            <ContentHeader
              titleComponent={<Typography variant="h6">Read-Only Access</Typography>}>
                <>
                  <Typography variant="body1" style={{ marginTop: "10px" }}>
                    - If you only want to read the data:
                  </Typography>
                  <Typography variant="body1" style={{ marginTop: "10px" }}>
                    - Raise a PR to get added to the DevHub readers group for quick access.
                    <Link href="https://github.com/vonage-technology/vcp-fabric-okta-sync/blob/main/github-okta-group-sync-requests/devhub-readers.yaml" target="_blank">
                      GitHub Repository
                    </Link>.
                    
                  </Typography>
                  <Typography variant="body1" style={{ marginTop: "10px" }}>
                    - Note: Readers cannot deploy components or services to DevHub. This option is more suited for those in the Management stream.
                  </Typography>
                 
                </>
              </ContentHeader>
          </div>
        </Grid>


      </Grid>
    </Content>
  </Page>

  );
};

