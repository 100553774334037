import { useApi } from "@backstage/core-plugin-api";
import { catalogApiRef } from "@backstage/plugin-catalog-react";
import { MenuItem, TextField } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { FieldProps, UiSchema } from "@rjsf/utils";
import React, { useCallback, useEffect, useState } from "react";
import InputLabel from '@mui/material/InputLabel';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import FormHelperText from '@mui/material/FormHelperText';


export const allowArbitraryValues = (uiSchema: UiSchema): boolean =>
  (uiSchema["ui:options"]?.allowArbitraryValues as boolean) ?? true;

export const CloudVPCRegionExtension = ({
  required,
  rawErrors,
  formData,
  idSchema,
  onChange,
}: FieldProps<string>) => {
  const catalogApi = useApi(catalogApiRef);
  const [vpcEntityData, setVPCEntityData] = useState(null);
  const [entityVPCRefs, setEntityVPCRefs] = useState<{ groupByRegion: string; label: string; value: string; }[]>([]);
  const [entityAtmosRefs, setEntityAtmosRefs] = useState([]);
  const [hasAtmosAccountChanged, setHasAtmosAccountChanged] = useState(false);
  const [selectedVPC, setSelectedVPC] = useState({ value: "", label: "" });
  const [selecteAtmosAccount, setSelecteAtmosAccount] = useState("");
  //const cloudProvider:SelectItem[] = [{label:'aws', value:'aws'},{label:'gcp', value:'gcp'}];
  const [cloudProvider,setCloudProvider] = useState('aws');
  
  // Use useEffect to do something when the selected value changes
  useEffect(() => {
    fetchAtmosAccountData();
  }, []);

  // Use useEffect to do something when the selected value changes
  useEffect(() => {
    setSelectedVPC({
      label: "",
      value: "",
    });
    setHasAtmosAccountChanged(true);
    
  }, [selecteAtmosAccount, cloudProvider]);

  const changed = (value: any) => {
    if (value) {
      //this is going to be broadcasted inside template and will be available as an outcome, can be accessed like parameter.[whatevername].split(:)
      onChange(
        value.atmosName + ":" + value.vpcValue + "~" + value.vpcRegion + "#" + value.cloudProvider
      );
    }
  };

  const onSelectAtmosAccount = useCallback(
    (_: any, value: any) => {
      setSelecteAtmosAccount(value);
      changed({ atmosName: value || '', vpcValue: '',  vpcRegion:'', cloudProvider:cloudProvider});
    },
    [changed]
  );

  const onSelectVPC = useCallback(
    (_: any, value: any) => {
      setSelectedVPC(value);
      changed(value);
      changed({ atmosName: selecteAtmosAccount, vpcValue: value.value,  vpcRegion:value.groupByRegion, cloudProvider:cloudProvider });
    },
    [changed]
  );


  const onSelectCloudProvider = (event: SelectChangeEvent) => {
    setCloudProvider(event.target.value);
    changed({ atmosName: selecteAtmosAccount, vpcValue: selectedVPC.value,  vpcRegion:selectedVPC.label,  cloudProvider:event.target.value});
  };
  

  const onVPCTextFieldFocus = () => {
    //Check if API data has already been fetched for VPC's
    if (!vpcEntityData || hasAtmosAccountChanged) {
      // Fetch data if not already fetched
      setHasAtmosAccountChanged(false);
      fetchVPCData();
    }
  };

  const fetchAtmosAccountData = async () => {
    try {
      const response: any = await catalogApi.getEntities({
          filter: {
            kind: "resource",
            "spec.type": "atmos-account"
          },
        });
  
      const tempEntityRef = response?.items.map((e: any) => {
        return e.metadata.name
      });

      setEntityAtmosRefs(tempEntityRef);

      setSelecteAtmosAccount("");

    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
    }
  };

  const fetchVPCData = async () => {
    try {

      let response: any = [];
      let tempEntityRef = [{groupByRegion:'', label:'', value:''}];

      if(cloudProvider == 'aws')
      {
        response = await catalogApi.getEntities({
            filter: {
              kind: "resource",
              "spec.type": "atmos-vpc",
              "spec.dependsOn": `resource:${selecteAtmosAccount}`,
            },
          });
        
        tempEntityRef = response?.items.map((e: any) => {
          return {
            groupByRegion: e.spec?.tags?.Region,
            label: e.spec?.tags?.Name,
            value: e.metadata.name,
          };
        });

        // autocomplete component require array to be sorted for its grouping feature
        tempEntityRef.sort((a:any, b:any) => {
          const nameComparison = a.groupByRegion.localeCompare(b.groupByRegion);
          return nameComparison;
        });
      }
        

      setEntityVPCRefs(tempEntityRef);

      setVPCEntityData(response);

      setSelectedVPC({
        label: "",
        value: "",
      });
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
    }
  };

  

  return (
    <>

      <FormControl
        margin="normal"
        variant="standard"
        required={required}
        error={rawErrors && rawErrors.length > 0 && !formData}
      >
        <InputLabel id="demo-simple-select-helper-label">Cloud</InputLabel>
        <Select
            labelId="demo-simple-select-helper-label"
            value= {cloudProvider}
            onChange={onSelectCloudProvider}
            label="Cloud"
            required={required}
        >
          <MenuItem value={'aws'}>aws</MenuItem>
          <MenuItem value={'gcp'}>gcp</MenuItem>
        </Select>
        <FormHelperText>selct cloud provider</FormHelperText>
      </FormControl>

      <FormControl
        margin="normal"
        required={required}
        error={rawErrors &&  rawErrors.length > 0 && !formData}
      >

      <Autocomplete
          disabled={entityAtmosRefs?.length === 1}
          id={idSchema?.$id}
          value={selecteAtmosAccount}
          onChange={onSelectAtmosAccount}
          options={(entityAtmosRefs || [])}
          autoSelect
          freeSolo={false}
          renderInput={params => (
            <TextField
              name="Atmos"
              {...params}
              label="Atmos"
              margin="normal"
              helperText="Enter Atmos Account (Name of the Atmos Account the cluster will be deployed into.)"
              //onFocus={onAtmosTextFieldFocus}
              variant="outlined"
              required={required}
              InputProps={params.InputProps}
            />
          )}
        />
      </FormControl>
     

      <FormControl
        margin="normal"
        required={required}
        error={rawErrors && rawErrors.length > 0 && !formData}
      >
        <Autocomplete
          id={idSchema?.$id}
          value={selectedVPC}
          groupBy={(vpc: any) => vpc?.groupByRegion}
          getOptionLabel={(vpc: any) => vpc?.label}
          onChange={onSelectVPC}
          options={entityVPCRefs}
          freeSolo={false}
          renderInput={(params) => (
            <TextField
              name="vpc"
              onFocus={onVPCTextFieldFocus}
              {...params}
              label="VPC"
              margin="normal"
              helperText="The VPC"
              variant="outlined"
              required={required}
              InputProps={params.InputProps}
            />
          )}
        />
      </FormControl>
    </>
  );
};
