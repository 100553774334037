import React from 'react';
import { AppTheme } from "@backstage/core-plugin-api"
import DarkIcon from '@material-ui/icons/Brightness2';
import LightIcon from '@material-ui/icons/WbSunny';
import { vividLightTheme } from "./lightTheme";
import { vividDarkTheme } from "./darkTheme";
import { UnifiedThemeProvider } from "@backstage/theme";


export const themes: AppTheme[] = [
    {
      id: 'light',
      title: 'Light Theme',
      variant: 'light',
      icon: <LightIcon />,
      Provider: ({ children }) => (
        <UnifiedThemeProvider theme={vividLightTheme} children={children} />
      ),
    },
    {
      id: 'dark',
      title: 'Dark Theme',
      variant: 'dark',
      icon: <DarkIcon />,
      Provider: ({ children }) => (
        <UnifiedThemeProvider theme={vividDarkTheme} children={children} />
      ),
    },
  ];