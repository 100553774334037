import { useApi } from '@backstage/core-plugin-api';
import { catalogApiRef } from '@backstage/plugin-catalog-react';
import { TextField } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { FieldProps, UiSchema } from '@rjsf/utils';
import React, { useCallback, useEffect } from 'react';
import { useSetState } from 'react-use';
import useAsync from 'react-use/lib/useAsync';

export const allowArbitraryValues = (uiSchema: UiSchema): boolean => 
(uiSchema && uiSchema['ui:options']?.allowArbitraryValues as boolean) ?? true;
  
  
  export const DomainSystemPicker = ({
  onChange,
  required,
  uiSchema,
  rawErrors,
  formData,
  idSchema,
}: FieldProps<string>) => {
  
  const catalogApi = useApi(catalogApiRef);
  const [state, setState] = useSetState<any>();
  //const allowArbitraryValues = (uiSchema && uiSchema['ui:options']?.allowArbitraryValues) ?? true;

  // on combo change
  const changed = (state: any) => { 
    setState(state);
    if (state.domain && state.system){
        onChange("resource:"+state.domain+"~"+state.system); 
    }

  }

  //fetching domain entities
  const { value: domainEntities, loading } = useAsync(async() => catalogApi.getEntities({ filter: { kind:'domain' } }));

  // fetching system entities based on filter defined in the template
  const { value: systemEntities} = useAsync(async() =>
  {
     const defaultFilters:any = (uiSchema && uiSchema['ui:options'])?(uiSchema['ui:options']?.defaultFilters):{};
    const customFilters:any = (uiSchema && uiSchema['ui:options'])?(uiSchema['ui:options']?.customFilters):undefined;

   
    let entities = await catalogApi.getEntities({ filter: { ...defaultFilters } });
    
    //if defined in template, applying custom filter
    if(customFilters && entities?.items?.length > 0 )
    {
      entities = executeCustomFilter(customFilters, entities)
    }

    return entities;
  
  });

  const executeCustomFilter = (customFilters:any, systemEntities:any) => {

    switch(customFilters.type)
    {
      case 'SpecTypeDefault' : return filterSpecTypeDefault(systemEntities); 
                            
      default: return systemEntities; 
    }
  }


  const filterSpecTypeDefault = (systemEntities:any) =>{

    systemEntities.items = systemEntities?.items.filter((item:any) =>(!item?.spec?.type || item.spec.type == "default"))

    return systemEntities;
  }  
  
  
  // setting the entityRef/Domain using substring logic
  let entityRefs = domainEntities?.items.map(e =>  
    {
      return  e.spec?.domain || e.metadata.name.split('-')[0];
    }
  )

  // setting the systemRef/System 
  let systemRefs = systemEntities?.items.filter((item:any) =>(item.spec.domain == state.domain)).map((e:any) => e.metadata.name);
  

  const onSelectDomain = useCallback(
    ( _: any, value: any ) => {
      changed({ domain: value || '',system:''});      
    },
    [changed],
  );

  // on system drop down select
  const onSelectSystem = useCallback(
    ( _: any, value: any ) => {
      changed({ system: value || '', domain: state.domain});      
    },
    [changed],
  );

  //
  useEffect(() => {
    
    if (entityRefs?.length === 1) {
      changed({ domain: entityRefs[0] || '' });
      }
  }, [entityRefs, changed]);

  
   
  return (
    <>
    <FormControl
        margin="normal"
        required={required}
        error={rawErrors && rawErrors.length > 0 && !formData}
      >
        <Autocomplete
          
          disabled={entityRefs?.length === 1}
          id={idSchema?.$id}
          value={(state.domain as string) || ''}
          loading={loading}
          onChange={onSelectDomain}
          options={(entityRefs || [])}
          autoSelect
          freeSolo={false}
          renderInput={params => (
            <TextField
              name="domain"
              {...params}
              label="Domain"
              margin="normal"
              helperText="The domain to use"
              variant="outlined"
              required={required}
              InputProps={params.InputProps}
            />
          )}
        />
      </FormControl>
      <FormControl
        margin="normal"
        required={required}
        error={rawErrors && rawErrors.length > 0 && !formData}
      >
        <Autocomplete
          
          disabled={entityRefs?.length === 1}
          id={idSchema?.$id}
          value={(state.system as string) || ''}
          loading={loading}
          onChange={onSelectSystem}
          options={(systemRefs || []).filter((value, index, self) => self.indexOf(value) === index)}
          autoSelect
          freeSolo={false}
          renderInput={params => (
            <TextField
              name="system"
              {...params}
              label="System"
              margin="normal"
              helperText="System"
              variant="outlined"
              required={required}
              InputProps={params.InputProps}
            />
          )}
        />
      </FormControl>
      
    </>
  );
};
