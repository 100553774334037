import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  svg: {
    width: 'auto',
    height: 35,
  },
});

const LogoIcon = () => {
  const classes = useStyles();

  return (
    <svg className={classes.svg} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M21.9396 0L10.1242 11.7848L6.98784 14.9131L3.98227 17.9109C3.31647 18.5749 3.31647 19.6517 3.98227 20.3181C4.64806 20.9822 5.72759 20.9822 6.39576 20.3181L9.39182 17.3298L9.40134 17.3203L13.2677 13.464C13.9335 12.7999 15.013 12.7999 15.6812 13.464C16.347 14.128 16.347 15.2048 15.6812 15.8712L11.8148 19.7276L11.8053 19.737L10.9517 20.5885C10.2859 21.2525 10.2859 22.3293 10.9517 22.9957C11.6175 23.6622 12.697 23.6598 13.3652 22.9957L14.2283 22.1348L20.7483 15.6317C21.4141 14.9676 22.4937 14.9676 23.1618 15.6317C23.83 16.2957 23.8276 17.3725 23.1618 18.0389L16.6418 24.542L14.8917 26.2876L11.0706 30.0989C10.4048 30.7629 10.4048 31.8397 11.0706 32.5061C11.7364 33.1702 12.8159 33.1702 13.4841 32.5061L17.3052 28.6948L19.0553 26.9493L21.3475 24.663C22.0133 23.9989 23.0929 23.9989 23.761 24.663C24.4268 25.3271 24.4268 26.4038 23.761 27.0702L21.4688 29.3565L18.4632 32.3543C17.7974 33.0184 17.7974 34.0951 18.4632 34.7616C19.129 35.4256 20.2086 35.4256 20.8767 34.7616L23.8823 31.7638L27.0187 28.6356L38.8341 16.8507L36.9865 1.8499L21.9396 0ZM35.9046 16.1701L31.2013 20.8612L29.6129 9.18785L17.9092 7.60358L22.6125 2.91241L34.4494 4.3615L35.9022 16.1677L35.9046 16.1701ZM3.08583 23.7428C2.37961 24.4472 1.23588 24.4472 0.529661 23.7428C-0.176554 23.0384 -0.176554 21.8976 0.529661 21.1932C1.23588 20.4889 2.37961 20.4889 3.08583 21.1932C3.79204 21.8976 3.79204 23.0384 3.08583 23.7428ZM10.3144 33.5022C11.0206 34.2066 11.0206 35.3474 10.3144 36.0518C9.60821 36.7561 8.46447 36.7561 7.75826 36.0518C7.05204 35.3474 7.05204 34.2066 7.75826 33.5022C8.46447 32.7978 9.60821 32.7978 10.3144 33.5022ZM6.88559 36.9222C7.59181 37.6266 7.59181 38.7673 6.88559 39.4717C6.17938 40.1761 5.03564 40.1761 4.32943 39.4717C3.62321 38.7673 3.62321 37.6266 4.32943 36.9222C5.03564 36.2178 6.17938 36.2178 6.88559 36.9222Z" fill="url(#paint0_linear_10_1073)"/>
    <defs>
    <linearGradient id="paint0_linear_10_1073" x1="37.1691" y1="2.2438" x2="-0.0975543" y2="36.4862" gradientUnits="userSpaceOnUse">
    <stop stopColor="#FA7454"/>
    <stop offset="0.484375" stopColor="#D6219C"/>
    <stop offset="1" stopColor="#9941FF"/>
    </linearGradient>
    </defs>
    </svg>
    

  );
};

export default LogoIcon;
