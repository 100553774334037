import {
  PageTheme,
  palettes,
  genPageTheme,
  createUnifiedTheme,
} from "@backstage/theme";

//import '@vonage-technology/vivid/styles/tokens/theme-light.css';

//onst abc:CSSProperties = dropShadow(0px 2px 8px #00000040) drop-shadow(0px 2px 4px #00000040) drop-shadow(0px 4px 4px #00000040);

const CANVAS_COLOR = "#000000"; //--vvd-color-canvas: #000000;
const TEXT_CONTRAST = "#ffffff"; //  --vvd-color-canvas-text: #ffffff;
const PRIMARY_COLOR = "#b27bf2"; // --vvd-color-cta-500: #b27bf2;
const ERROR_COLOR = "#f75959"; // --vvd-color-alert-500: #f75959;
const WARNING_COLOR = "#e07902"; //--vvd-color-warning-500: #e07902;
const INFO_COLOR = "#2997f0"; //--vvd-color-information-500: #2997f0;
const SUCCESS_COLOR = "#30a849"; //--vvd-color-success-500: #30a849;
const SIDE_DRAWER_BACKGROUND_COLOR = "#ffffff"; // --vvd-color-neutral-950: #ffffff;
const NAVIGATION_INDICATOR_COLOR = "#9BF0E1";
const HOVER_COLOR = "#1a1a1a"; //--vvd-color-neutral-50 : #1a1a1a;

export const pageTheme: Record<string, PageTheme> = {
  home: genPageTheme({ colors: [CANVAS_COLOR, CANVAS_COLOR], shape: "none" }),
  documentation: genPageTheme({
    colors: [CANVAS_COLOR, CANVAS_COLOR],
    shape: "none",
  }),
  tool: genPageTheme({ colors: [CANVAS_COLOR, CANVAS_COLOR], shape: "none" }),
  service: genPageTheme({
    colors: [CANVAS_COLOR, CANVAS_COLOR],
    shape: "none",
  }),
  website: genPageTheme({
    colors: [CANVAS_COLOR, CANVAS_COLOR],
    shape: "none",
  }),
  other: genPageTheme({ colors: [CANVAS_COLOR, CANVAS_COLOR], shape: "none" }),
  app: genPageTheme({ colors: [CANVAS_COLOR, CANVAS_COLOR], shape: "none" }),
  apis: genPageTheme({ colors: [CANVAS_COLOR, CANVAS_COLOR], shape: "none" }),
  card: genPageTheme({ colors: [CANVAS_COLOR, CANVAS_COLOR], shape: "none" }),
};

export const vividDarkTheme = createUnifiedTheme({
    fontFamily: "SpeziaCompleteVariableUpright",
    defaultPageTheme: "home",
    pageTheme: pageTheme,

    palette: {
      ...palettes.dark,
      type: "dark",

      background: {
        default: CANVAS_COLOR, //ok
        paper: CANVAS_COLOR, //ok
      },
      primary: {
        main: PRIMARY_COLOR, // ok
      },
      secondary: {
        main: TEXT_CONTRAST, //ok
      },
      error: {
        main: ERROR_COLOR, //alert
      },
      warning: {
        main: WARNING_COLOR, //ok
      },
      info: {
        main: INFO_COLOR, //ok
      },
      success: {
        main: SUCCESS_COLOR, //ok
      },
      border: TEXT_CONTRAST,
      textContrast: TEXT_CONTRAST, // ok
      // textVerySubtle: "#DDD",
      // textSubtle: "#6E6E6E",
      // highlight: "#FFFBCC",
      // errorText: "#CA001B",
      // infoText: "#004e8a",
      // warningText: TEXT_CONTRAST,
      // linkHover: "#2196F3",
      link: TEXT_CONTRAST,
      //gold: yellow.A700,

      status: {
        ok: "#e1f8e5", //ok
        warning: "#FDF5D4", // ok
        error: "#E22134",
        running: "#2E77D0",
        pending: "#FFED51",
        aborted: "#757575",
      },
      bursts: {
        fontColor: "#FEFEFE",
        slackChannelText: "#ddd",
        backgroundColor: {
          default: "#7C3699",
        },
        gradient: {
          linear: "linear-gradient(-137deg, #4BB8A5 0%, #187656 100%)",
        },
      },
      banner: {
        info: "#2E77D0",
        error: "#E22134",
        text: "#FFFFFF",
        link: TEXT_CONTRAST,
        warning: "#FF9800",
      },
      navigation: {
        background: SIDE_DRAWER_BACKGROUND_COLOR,
        indicator: NAVIGATION_INDICATOR_COLOR,
        color: "#b5b5b5",
        selectedColor: CANVAS_COLOR,
        navItem: {
          hoverBackground: "#757575",
        },
        submenu: {
          background: HOVER_COLOR,
        },
      },
      pinSidebarButton: {
        icon: "#181818",
        background: "#BDBDBD",
      },
      tabbar: {
        indicator: NAVIGATION_INDICATOR_COLOR,
      },
    },


  components: {
    // MuiCssBaseline: {
    //   styleOverrides: {
    //     '@font-face': myCustomFont,
    //   },
    // },
    BackstageHeader: {
      styleOverrides: {
        header: ({ theme }) => ({
          backgroundColor: theme.palette.background.default,
          color: theme.palette.textContrast,
          backgroundImage: "unset",
          paddingBottom: theme.spacing(1),
        }),
        title: ({}) => ({
          color: TEXT_CONTRAST,
          fontWeight: 900,
        }),
        subtitle: ({}) => ({
          color: TEXT_CONTRAST,
        }),
        type: ({}) => ({
          color: TEXT_CONTRAST,
        }),
      },
    },
    BackstageHeaderLabel: {
      styleOverrides: {
        value: {
          color: TEXT_CONTRAST,
        },
        label: {
          color: TEXT_CONTRAST,
        },
      },
    },
    BackstageHeaderTabs: {
      styleOverrides: {
        defaultTab: {
          fontSize: "inherit",
          textTransform: "none",
        },
      },
    },

    BackstageOpenedDropdown: {
      styleOverrides: {
        icon: {
          "& path": {
            fill: CANVAS_COLOR,
          },
        },
      },
    },
    BackstageSidebar: {
      // styleOverrides: {
      //    drawer: {
      //      background: '#0d0d0d',
      //   },
      // }
    },

    BackstageItemCardHeader: {
      styleOverrides: {
        root: {
          // Reduce padding between header and content
          //color: '#ffffff',
          color: "var(--vvd-color-surface-12dp)",
          // background-image: unset;
          backgroundImage: "none !important",
        },
      },
    },

    MuiBackdrop: {
      styleOverrides: {
        root: {
          backgroundColor: "rgba(9,30,69,0.54)",
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          color: TEXT_CONTRAST + "!important",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 6,
          textTransform: "none",
        },
        contained: {
          styleOverrides: {
            boxShadow: "0 0 0 1px transparent",
            color: CANVAS_COLOR + "!important",
            backgroundColor: PRIMARY_COLOR + "!important",
          },
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: ({}) => ({
          borderRadius: 14,
          //backgroundColor: theme.palette.grey[50],
          //color: theme.palette.primary.dark,
          margin: 4,
        }),
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          "&[aria-expanded]": {
            backgroundColor: "#26385A",
            color: CANVAS_COLOR,
          },
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          padding: 10,
        },
        switchBase: {
          padding: 12,
        },
        thumb: {
          backgroundColor: CANVAS_COLOR,
          height: 14,
          width: 14,
        },
        track: {
          borderRadius: 9,
        },
      },
    },

    MuiTabs: {
      styleOverrides: {
        indicator: {
          transition: "none",
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        button: {
          textTransform: "none",
        },
        h6: {
          color: TEXT_CONTRAST,
        },
      },
    },

    MuiCard: {
      styleOverrides: {
        root: {
          background: "#2b2b2b",
          elevation: 6,
        },
      },
    },
    MuiCardHeader: {
      styleOverrides: {
        root: {
          background: "#2b2b2b",
          elevation: 4,
        },
        title: {
          fontSize: "18px",
        },
      },
    },

    MuiList: {
      styleOverrides: {
        root: {
          background: "#2b2b2b" + "!important",
        },
      },
    },

    BackstageTable: {
      styleOverrides: {
        root: {
          "&> :first-child": {
            borderBottom: "1px solid #D5D5D5",
            boxShadow: "none",
          },
          "& th": {
            borderTop: "none",
            textTransform: "none !important",
          },
        },
      },
    },
  },
});
