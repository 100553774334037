import {
  PageTheme,
  palettes,
  genPageTheme,
  createUnifiedTheme,
} from "@backstage/theme";

//import '@vonage-technology/vivid/styles/tokens/theme-light.css';
// import SpeziaCompleteVariableUpright from '../assets/fonts/Spezia_Web_Complete/VariableFont/Complete/SpeziaCompleteVariableUprightWeb.woff2';

// const myCustomFont = {
//   fontFamily: 'My-Custom-Font',
//   fontStyle: 'normal',
//   fontDisplay: 'swap',
//   fontWeight: ' 1 1000',
//   fontStretch: '50% 200%',
//   src: `
//       local('My-Custom-Font'),
//       url(${SpeziaCompleteVariableUpright}) format('woff2'),
//     `,
// };

const CANVAS_COLOR = "#ffffff"; //--vvd-color-canvas
const TEXT_CONTRAST = "#000000"; // --vvd-color-canvas-text
const PRIMARY_COLOR = "#9941ff"; // --vvd-color-cta-500
const ERROR_COLOR = "#e61d1d"; // --vvd-color-alert-500
const WARNING_COLOR = "#be5702"; //--vvd-color-warning-500: #be5702;
const INFO_COLOR = "#0276d5"; //--vvd-color-information-500: #0276d5;
const SUCCESS_COLOR = "#1c8731"; //--vvd-color-success-500: #1c8731;
const SIDE_DRAWER_BACKGROUND_COLOR = "#0d0d0d"; //--vvd-color-neutral-950: #0d0d0d;
const NAVIGATION_INDICATOR_COLOR = "#9BF0E1";
const HOVER_COLOR = "#F2F2F2"; //--vvd-color-neutral-50 : #F2F2F2;

export const pageTheme: Record<string, PageTheme> = {
  home: genPageTheme({ colors: [CANVAS_COLOR, CANVAS_COLOR], shape: "none" }),
  documentation: genPageTheme({
    colors: [CANVAS_COLOR, CANVAS_COLOR],
    shape: "none",
  }),
  tool: genPageTheme({ colors: [CANVAS_COLOR, CANVAS_COLOR], shape: "none" }),
  service: genPageTheme({
    colors: [CANVAS_COLOR, CANVAS_COLOR],
    shape: "none",
  }),
  website: genPageTheme({
    colors: [CANVAS_COLOR, CANVAS_COLOR],
    shape: "none",
  }),
  other: genPageTheme({ colors: [CANVAS_COLOR, CANVAS_COLOR], shape: "none" }),
  app: genPageTheme({ colors: [CANVAS_COLOR, CANVAS_COLOR], shape: "none" }),
  apis: genPageTheme({ colors: [CANVAS_COLOR, CANVAS_COLOR], shape: "none" }),
  card: genPageTheme({ colors: [CANVAS_COLOR, CANVAS_COLOR], shape: "none" }),
};

export const vividLightTheme = createUnifiedTheme({
  fontFamily: "SpeziaCompleteVariableUpright",
    defaultPageTheme: "home",
    pageTheme: pageTheme,

    palette: {
      ...palettes.light,
      type: "light",

      background: {
        default: CANVAS_COLOR, //ok
        paper: CANVAS_COLOR, //ok
      },
      primary: {
        main: PRIMARY_COLOR, // ok
      },
      secondary: {
        main: TEXT_CONTRAST, //ok
      },
      error: {
        main: ERROR_COLOR, //alert
      },
      warning: {
        main: WARNING_COLOR, //ok
      },
      info: {
        main: INFO_COLOR, //ok
      },
      success: {
        main: SUCCESS_COLOR, //ok
      },
      border: TEXT_CONTRAST,
      textContrast: TEXT_CONTRAST, // ok
      // textVerySubtle: "#DDD",
      // textSubtle: "#6E6E6E",
      // highlight: "#FFFBCC",
      // errorText: "#CA001B",
      // infoText: "#004e8a",
      // warningText: TEXT_CONTRAST,
      // linkHover: "#2196F3",
      link: TEXT_CONTRAST,
      //gold: yellow.A700,

      status: {
        ok: "#e1f8e5", //ok
        warning: "#FDF5D4", // ok
        error: "#E22134",
        running: "#2E77D0",
        pending: "#FFED51",
        aborted: "#757575",
      },
      bursts: {
        fontColor: "#FEFEFE",
        slackChannelText: "#ddd",
        backgroundColor: {
          default: "#7C3699",
        },
        gradient: {
          linear: "linear-gradient(-137deg, #4BB8A5 0%, #187656 100%)",
        },
      },
      banner: {
        info: "#2E77D0",
        error: "#E22134",
        text: "#FFFFFF",
        link: TEXT_CONTRAST,
        warning: "#FF9800",
      },
      navigation: {
        background: SIDE_DRAWER_BACKGROUND_COLOR,
        indicator: NAVIGATION_INDICATOR_COLOR,
        color: "#b5b5b5",
        selectedColor: CANVAS_COLOR,
        navItem: {
          hoverBackground: "#404040",
        },
        submenu: {
          background: HOVER_COLOR,
        },
      },
      pinSidebarButton: {
        icon: "#181818",
        background: "#BDBDBD",
      },
      tabbar: {
        indicator: NAVIGATION_INDICATOR_COLOR,
      },
    },

  components: {
    // MuiCssBaseline: {
    //   styleOverrides: {
    //     '@font-face': myCustomFont,
    //   },
    // },
    BackstageHeader: {
      styleOverrides: {
        header: ({ theme }) => ({
          backgroundColor: theme.palette.background.default,
          color: theme.palette.textContrast,
          backgroundImage: "unset",
          paddingBottom: theme.spacing(1),
        }),
        title: ({}) => ({
          color: TEXT_CONTRAST,
          fontWeight: 900,
        }),
        subtitle: ({}) => ({
          color: TEXT_CONTRAST,
        }),
        type: ({}) => ({
          color: TEXT_CONTRAST,
        }),
      },
    },
    BackstageHeaderLabel: {
      styleOverrides: {
        value: {
          color: TEXT_CONTRAST,
        },
        label: {
          color: TEXT_CONTRAST,
        },
      },
    },
    BackstageHeaderTabs: {
      styleOverrides: {
        defaultTab: {
          fontSize: "inherit",
          textTransform: "none",
        },
      },
    },

    BackstageOpenedDropdown: {
      styleOverrides: {
        icon: {
          "& path": {
            fill: CANVAS_COLOR,
          },
        },
      },
    },
    BackstageSidebar: {
      // styleOverrides: {
      //    drawer: {
      //      background: '#0d0d0d',
      //   },
      // }
    },

    BackstageItemCardHeader: {
      styleOverrides: {
        root: {
          // Reduce padding between header and content
          //color: '#ffffff',
          color: TEXT_CONTRAST,
          // background-image: unset;
          backgroundImage: "none !important",
        },
      },
    },

    MuiBackdrop: {
      styleOverrides: {
        root: {
          backgroundColor: "rgba(9,30,69,0.54)",
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          color: TEXT_CONTRAST + "!important",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 6,
          textTransform: "none",
        },
        contained: {
          styleOverrides: {
            boxShadow: "0 0 0 1px transparent",
            color: CANVAS_COLOR + "!important",
            backgroundColor: PRIMARY_COLOR + "!important",
          },
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: ({}) => ({
          borderRadius: 14,
          //backgroundColor: theme.palette.grey[50],
          //color: theme.palette.primary.dark,
          margin: 4,
        }),
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          "&[aria-expanded]": {
            backgroundColor: "#26385A",
            color: CANVAS_COLOR,
          },
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          padding: 10,
        },
        switchBase: {
          padding: 12,
        },
        thumb: {
          backgroundColor: CANVAS_COLOR,
          height: 14,
          width: 14,
        },
        track: {
          borderRadius: 9,
        },
      },
    },

    MuiTabs: {
      styleOverrides: {
        indicator: {
          transition: "none",
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        button: {
          textTransform: "none",
        },
        h6: {
          color: TEXT_CONTRAST,
        },
      },
    },

    MuiCard: {
      styleOverrides: {
        root: {
          background: CANVAS_COLOR,
          elevation: 6,
        },
      },
    },
    MuiCardHeader: {
      styleOverrides: {
        root: {
          background: CANVAS_COLOR,
          elevation: 4,
        },
        title: {
          fontSize: "18px",
        },
      },
    },

    MuiList: {
      styleOverrides: {
        root: {
          background: CANVAS_COLOR + "!important",
        },
      },
    },

    BackstageTable: {
      styleOverrides: {
        root: {
          "&> :first-child": {
            borderBottom: "1px solid #D5D5D5",
            boxShadow: "none",
          },
          "& th": {
            borderTop: "none",
            textTransform: "none !important",
          },
        },
      },
    },
  },
});
